import { useState, useEffect } from "react";
import { query, where, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase-config"; // Import your Firebase config

export const useTabs = (organizationId) => {
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTabs = async () => {
      console.log("organization tab Id", organizationId);
      try {
        const q = query(
          collection(db, "organization_tabs"),
          where("organizationId", "==", organizationId)
        );
        const querySnapshot = await getDocs(q);
        console.log("query snapshot", querySnapshot);

        const tabsData = querySnapshot.docs.map((doc) => doc.data());
        console.log("The tabs data ---->", tabsData);
        setTabs(tabsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchTabs();
    }
  }, [organizationId]);

  return { tabs, loading, error };
};
