import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

// Function to generate random amounts for each day in the given date range
const generateRandomData = (startDate, endDate, minAmount, maxAmount) => {
  const data = [];
  let currentDate = new Date(startDate.getTime());

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  while (currentDate <= endDate) {
    const randomAmount = Math.floor(
      Math.random() * (maxAmount - minAmount + 1) + minAmount
    );

    const actualAmount = randomAmount * 0.8; // Example calculation

    data.push({
      name: currentDate.toLocaleDateString("en-US"),
      "Estimated Reimbursement": randomAmount,
      Amount: currentDate < thirtyDaysAgo ? actualAmount : null,
    });

    currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
  }

  return data;
};

// Function to generate random amounts for each week in the given date range
const generateWeeklyData = (
  startDate,
  endDate,
  minReimbursement,
  maxReimbursement
) => {
  const data = [];
  let currentDate = new Date(startDate.getTime());

  const fourWeeksAgo = new Date();
  fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28);

  while (currentDate <= endDate) {
    const weekStart = new Date(currentDate.getTime());
    const weekEnd = new Date(currentDate.getTime());
    weekEnd.setDate(weekEnd.getDate() + 6);

    const estimatedReimbursement = Math.floor(
      Math.random() * (maxReimbursement - minReimbursement + 1) +
        minReimbursement
    );
    // Actual amount is randomly generated between 70% and 90% of the estimated reimbursement
    const actualAmount = Math.floor(
      estimatedReimbursement * (Math.random() * (0.9 - 0.7) + 0.7)
    );

    data.push({
      name: `${weekStart.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })} - ${weekEnd.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })}`,
      "Estimated Reimbursement": estimatedReimbursement,
      Amount: currentDate < fourWeeksAgo ? actualAmount : null,
    });

    currentDate.setDate(currentDate.getDate() + 7); // Increment by 1 week
  }

  return data;
};

// Calculate the date range: today and 30 days back
const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 90);

// Generate new data with specified date range and random amounts
const newData = generateRandomData(startDate, endDate, 3000, 20000);

// Extract the new data for the chart
const uData = newData.map((item) => item["Estimated Reimbursement"]);
const pData = newData.map((item) => item.Amount);
const xLabels = newData.map((item) => item.name);

export const SimpleBarChart = () => {
  // console.log("STEVENDEBUG uData ", uData);
  // console.log("STEVENDEBUG uData ", newData);
  return (
    <BarChart
      className="w-full pl-12"
      height={400}
      //   yAxis={[
      //     {
      //       label: "Amount ($)",
      //       //   scaleType: "band",
      //     },
      //   ]}
      series={[
        { data: pData, label: "Actual Amount ($)", id: "amountId" },
        {
          data: uData,
          label: "Estimated Reimbursement",
          id: "EstimatedReimbursement",
        },
      ]}
      xAxis={[{ data: xLabels, scaleType: "band" }]}
    />
  );
};
