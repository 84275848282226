export const questionStudioRowData = [
  {
    question:
      "Were any infection outbreaks or incidents reported and documented, along with interventions taken?",
    tags: ["Home Health Care", "Hospice Care"],
    severity: "Normal",
  },
  {
    question:
      "Is there documentation of a comprehensive neurological examination, including assessments of cranial nerves, motor function, sensory function, reflexes, coordination, and gait?",
    tags: ["Clinical Quality", "Hospice Care", "Neurology"],
    severity: "Normal",
  },
  {
    question:
      "Is there documentation of the patient's emotional well-being and mental health?",
    tags: ["Clinical Quality", "Data", "Neurology"],
    severity: "Normal",
  },
  {
    question:
      "Was the patient well-aware of the treatment protocols, treatment method to be used, and in agreement with all the care provided?",
    tags: ["Clinical Quality", "Consultation"],
    severity: "Normal",
  },
  {
    question: "How long was the session?",
    tags: ["Behavioral Health", "Data"],
    severity: "Normal",
  },
  {
    question:
      "Are safety protocols documented, particularly when addressing crisis situations or challenging behaviors during ABA sessions?",
    tags: ["Behavioral Health", "Consultation", "Hospice"],
    severity: "Normal",
  },
  {
    question:
      "Is a minimum of 1 of the 7 autism DSM-5 checkboxes checked to indicate presence of DSM-5 symptoms in the session note? If so, is there a narrative present explaining the DSM-5 symptoms?",
    tags: [],
    severity: "Normal",
  },
  {
    question:
      "Were phrases that would indicate poor bedside manner observed in the session? Examples are XYZ ZYX ...",
    tags: [],
    severity: "Normal",
  },
  {
    question: "what was patient's blood loss as noted in document?",
    tags: [],
    severity: "Normal",
  },
];

export const defaultReviewAuditsRowData = [
  {
    provider: "Michael Williams",
    uploadDate: "Aug 29, 2023 at 05:43 am",
    score: 69,
    auditStatus: "Completed",
    reviewStatus: "Needs Review",
  },
  {
    provider: "Sarah Brown",
    uploadDate: "Aug 29, 2023 at 05:43 am",
    score: 75,
    auditStatus: "Completed",
    reviewStatus: "Needs Review",
  },
  {
    provider: "Lisa Chen",
    uploadDate: "Sep 01, 2023 at 02:30 pm",
    score: 72,
    auditStatus: "In Progress",
    reviewStatus: "Needs Review",
  },
  {
    provider: "William Anderson",
    uploadDate: "Sep 03, 2023 at 11:20 am",
    score: 67,
    auditStatus: "Not Started",
    reviewStatus: "Needs Review",
  },
  {
    provider: "Daniel Thompson",
    uploadDate: "Sep 04, 2023 at 08:30 am",
    score: 79,
    auditStatus: "In Progress",
    reviewStatus: "Needs Review",
  },
];
export const auditQuestions = [
  {
    question: "Is the type of session present in the report?",
    answer: "No",
    context: "The type of session is not present in the report.",
  },
  {
    question: "Were anti psychotics prescribed?",
    answer: "No",
    context:
      "The document states that the patient is currently only taking Melatonin 3mg QHS and denies any side effects from the medication.",
  },
  {
    question:
      "Are allergies or 'no known drug allergies' present in the documentation?",
    answer: "No",
    context: "Allergies are not listed in the report.",
  },
  {
    question:
      "Are goals present in the treatment plan measurable and specific?",
    answer: "No",
    context:
      "It is mentioned that the Psychiatric team will monitor mood and behavior, but the goals are not measurable and specific.",
  },
  {
    question: "Is face-to-face time congruent with the CPT code billed?",
    answer: "No",
    context:
      "Face to face time was documented as 54 minutes when a 90834 code was billed. This could be billed as a 90837.",
  },
  {
    question:
      "Is there a documented plan for what is going to happen next or further sessions?",
    answer: "No",
    context:
      "It is mentioned that there will be a follow up in 30 days but there is not a sufficiently documented plan for further sessions.",
  },
  {
    question:
      "Give a brief summary of the medical report with any information that is helpful to audit the documentation.",
    answer: "N/A",
    context:
      "A 70-year-old female patient, previously diagnosed with Schizophrenia, underwent a comprehensive psychiatric evaluation due to mood symptoms. Despite her diagnosis, she currently exhibits no schizophrenic symptoms, denies any feelings of depression, anxiety, hallucinations, or paranoia, and is benefiting from her current medication, melatonin, for sleep initiation.",
  },
];
