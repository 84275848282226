import React, { useState, useContext } from "react";
import { TableContent } from "../TableContent";
import { ModalContext } from "../../context/modal-context";
const generateData = (count) => {
  return Array(count)
    .fill(null)
    .map((_, index) => ({
      procedureCode: `BCBS${index + 1}`,
      priorAuthRequired: "56 DAYS",
      timeBetween1: "49 DAYS",
      timeBetween2: "20 Days",
      requirement1: "BBSCD",
      requirement2: "View Details",
    }));
};

const CustomBlockTable = ({
  title,
  policiesContracts,
  policiesHeaders,
  col,
  colNumber,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  console.log(
    `THE POLICIES HEADERS ARE: ${policiesHeaders} OF LENGTH ${policiesHeaders.length}`
  );
  const gridCol = policiesHeaders?.length;
  const allData = generateData(50); // Generate 50 rows of data
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("payer");

  const { openPdfModal, setOpenPdfModal } = useContext(ModalContext);
  const itemsPerPage = 8;

  const searchPolicies = (policies, term, field) => {
    const lowerCaseTerm = term.toLowerCase();
    return policies.filter((policy) => {
      const fieldValue = policy[field]?.toString().toLowerCase();
      return fieldValue && fieldValue.includes(lowerCaseTerm);
    });
  };

  const filteredPolicies = searchPolicies(
    policiesContracts,
    searchTerm,
    searchField
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPolicies.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredPolicies.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageRange = 2; // Number of pages to show on each side of the current page

    for (
      let i = Math.max(1, currentPage - pageRange);
      i <= Math.min(totalPages, currentPage + pageRange);
      i++
    ) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`w-8 h-8 flex items-center justify-center rounded transition duration-300 ${
            i === currentPage
              ? "bg-teal-600 text-white"
              : "text-gray-600 hover:bg-gray-100"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  console.log();

  return (
    <>
      <div className="flex flex-col space-y-4 bg-gray-50 p-4 h-screen">
        <h1 className="text-2xl underline-offset-4 font-semibold text-[#0D9488] px-2 py-2">
          {title}
        </h1>

        {(title === "Prior Authorization Policies" ||
          title === "Filing Requirements" ||
          title === "Contracts") && (
          <div className="flex flex-row justify-start items-end space-x-1 pl-1">
            <div className="relative flex">
              <input
                id="search"
                name="search"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div>
              <select
                id="location"
                name="location"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                className=" block w-full rounded-md border-0 py-1.5  pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {title === "Prior Authorization Policies" && (
                  <option value="procedure">Procedure</option>
                )}
                <option value="payer">Payer</option>
              </select>
            </div>
          </div>
        )}

        <div
          className={classNames(
            col ? colNumber : "grid-cols-6",
            "grid  gap-4 text-sm font-semibold text-gray-600 px-4 py-2"
          )}
        >
          {policiesHeaders.map((header, index) => {
            return <div key={index}>{header}</div>;
          })}
        </div>
        {currentItems.map((row, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-sm border border-gray-200"
          >
            <div
              className={`grid grid-cols-${gridCol} gap-4 px-4 py-4 items-center font-semibold text-sm`}
            >
              {TableContent(row, title, setOpenPdfModal)}
            </div>
          </div>
        ))}
        <div className="flex justify-between items-center px-4 py-2 text-sm">
          <div className="text-gray-500">
            Page {currentPage} of {totalPages}
          </div>
          <div className="flex space-x-2 items-center">
            <button
              onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className={`w-8 h-8 flex items-center justify-center border border-gray-300 rounded text-gray-500 transition duration-300 ${
                currentPage === 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              &lt;
            </button>
            {renderPageNumbers()}
            <button
              onClick={() =>
                handlePageChange(Math.min(totalPages, currentPage + 1))
              }
              disabled={currentPage === totalPages}
              className={`w-8 h-8 flex items-center justify-center border border-gray-300 rounded text-gray-500 transition duration-300 ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              &gt;
            </button>
          </div>
          <div className="text-gray-500">{filteredPolicies.length} items</div>
        </div>
      </div>
    </>
  );
};

export default CustomBlockTable;
