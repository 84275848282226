/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
"use client";

import { useState, useContext, useEffect } from "react";
import { EmailAvatar } from "../components/EmailAvatar";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
  BanknotesIcon,
  ChevronUpDownIcon,
  DocumentMagnifyingGlassIcon,
  BookOpenIcon,
  BriefcaseIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import { useLocation } from "react-router-dom";
import { PdfModal } from "../components/PdfModal";
import { FilterModal } from "../components/FilterModal";
import { NewTaskModal } from "../components/NewTaskModal";
import { ModalContext } from "../context/modal-context";
import { useUserContext } from "../context/user-context";
import { NewTemplateModal } from "../components/TemplateModal";
import { DetailsModal } from "../components/DetailsModal";
import { PdfViewer } from "../components/PdfViewer";
import { PolicyModal } from "../components/PolicyModal";
import { SubscriptionStatusModal } from "../components/SubscriptionStatusModal";
import { LocalityDescriptionModal } from "../components/LocalityDescriptionModal";
import { SidebarSkeletonLoader } from "../components/SkeletonSidebar";
import { useOrganization } from "../hooks/useOrganization";
import { useTabs } from "../hooks/useTab";
import { signOut } from "firebase/auth";
const navigation = [
  { name: "Overview", id: 1, href: "overview", icon: HomeIcon, current: true },
  {
    name: "Recon Copilot",
    id: 2,
    href: "chat",
    icon: AcademicCapIcon,
    current: false,
  },
  {
    name: "Contracts",
    id: 3,
    href: "billing",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Contracted Rates",
    id: 4,
    href: "contracted-rates",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Filing Requirements",
    id: 5,
    href: "filing",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Prior Authorizations",
    id: 6,
    href: "prior-auth",
    icon: FolderIcon,
    current: false,
  },

  {
    name: "Denials",
    id: 7,
    href: "denials",
    icon: ShieldCheckIcon,
    current: false,
  },

  {
    name: "Payment Variances",
    id: 8,
    href: "payment-variances",
    icon: ShieldCheckIcon,
    current: false,
  },

  {
    name: "Patient Payments",
    id: 9,
    href: "patient-payments",
    icon: BanknotesIcon,
    current: false,
  },
  {
    name: "Recon Phone Agent",
    id: 10,
    href: "ai-phone-agent",
    icon: UsersIcon,
    current: false,
    disabled: true,
  },

  {
    name: "Price InsightEngine",
    id: 11,
    href: "insight-engine",
    icon: UsersIcon,
    current: false,
    disabled: true,
  },
  {
    name: "Use Case",
    id: 12,
    href: "use-case",
    icon: BriefcaseIcon,
    current: false,
    disabled: true,
  },
  {
    name: "Audit Result",
    id: 13,
    href: "audit-result",
    icon: BookOpenIcon,
    current: false,
    disabled: true,
  },

  {
    name: "Review Audit",
    id: 14,
    href: "review-audit",
    icon: DocumentMagnifyingGlassIcon,
    current: false,
    disabled: true,
  },
  {
    name: "Question Studio",
    id: 15,
    href: "question-studio",
    icon: QuestionMarkCircleIcon,
    current: false,
    disabled: true,
  },
];
const teams = [
  { id: 1, name: "Sunrise Hospital", href: "#", initial: "H", current: false },
  //   { id: 2, name: 'Suburban Orthpedics', href: '#', initial: 'H', current: false },
  // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
];
const userNavigation = [
  { name: "Copy Token", href: "#", key: "copy-token" },
  { name: "Sign out", href: "#", key: "sign-out" },
];

// Todo: get this from db
const organizations = [
  { id: 1, organizationName: "Demo", initals: "D" },
  { id: 2, organizationName: "Vytalize", initals: "v" },
  { id: 3, organizationName: "Substrate", initals: "s" },
  { id: 4, organizationName: "Chart Review", initals: "s" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DropdownMenu = ({ items, onItemClick, className = "" }) => {
  console.log("ITEMMMMSSS --->", items);
  return (
    <MenuItems transition className={`${className}`}>
      {items.map((item) => (
        <MenuItem key={item.key}>
          {({ active }) => (
            <a
              href={item.href}
              onClick={(e) => {
                e.preventDefault();
                onItemClick(item.key);
              }}
              className={`block px-3 py-1  text-sm leading-6 text-gray-900 ${
                active ? "bg-gray-50" : ""
              }`}
            >
              {item.name}
            </a>
          )}
        </MenuItem>
      ))}
    </MenuItems>
  );
};

const Navigation = ({ navigationItems }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentPage = (href) => {
    return location.pathname === `/${href}`;
  };

  return (
    <ul role="list" className="-mx-2 space-y-1">
      {navigationItems.map((item) => (
        <li key={item.name}>
          <a
            href={`/${item.href}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${item.href}`);
            }}
            className={classNames(
              isCurrentPage(item.href)
                ? "bg-gray-50 text-indigo-600"
                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
            )}
          >
            <item.icon
              aria-hidden="true"
              className={classNames(
                isCurrentPage(item.href)
                  ? "text-indigo-600"
                  : "text-gray-400 group-hover:text-indigo-600",
                "h-6 w-6 shrink-0"
              )}
            />
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default function Example({ children }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const { userInformation, updateUserOrganizationId } = useUserContext();

  const { organization } = useOrganization(userInformation?.organizationId);
  const { tabs, loading, error } = useTabs(userInformation?.organizationId);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [selected, setSelected] = useState();
  const location = useLocation(); // Initialize the location

  // Function to determine if the navigation item is the current page
  const isCurrentPage = (href) => {
    // Normalize the paths by ensuring they both start with a '/'
    const normalizedHref = href.startsWith("/") ? href : `/${href}`;
    const normalizedPathname = location.pathname.endsWith("/")
      ? location.pathname.slice(0, -1)
      : location.pathname;
    // const normalizedPathname = location.pathname === '/' ? '/' : location.pathname.replace(/\/$/, '');

    // Check if the normalized current pathname is exactly the same as the normalized href
    return normalizedPathname === normalizedHref;
  };

  const tabsIds = tabs?.map((tab) => tab.tabId);
  const filteredNavigation = navigation.filter(
    (item) => tabsIds.includes(item.id) // Keep only navigation items with id in tabsIds
  );

  // Update the navigation array to set the current property dynamically
  const navigationWithCurrentPage = filteredNavigation.map((item) => ({
    ...item,
    current: isCurrentPage(item.href),
  }));

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleCopyToken = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      await navigator.clipboard.writeText(token);
      alert("User Token copied to clipboard!");
    } catch (error) {
      console.error("Error copying token: ", error);
      alert("Failed to copy JWT Token.");
    }
  };
  const handleItemClick = (key) => {
    if (key === "copy-token") {
      handleCopyToken();
    } else if (key === "sign-out") {
      handleSignOut();
    }
  };

  useEffect(() => {
    const matchingOrganization = organizations.find(
      (org) => org.id === userInformation?.organizationId
    );
    setSelected(matchingOrganization);
  }, [userInformation]); // This effect runs whenever userInformation changes

  const {
    openNewTaskModal,
    setOpenNewTaskModal,
    openPdfModal,
    setOpenPdfModal,
    openPdfViewer,
    setOpenPdfViewer,
    startPage,
    setStartPage,
    type,
    setType,
    contractModalPdfDetails,
    setContractModalPdfDetails,
    openNewTemplateModal,
    setOpenNewTemplateModal,
    openDetailsModal,
    setOpenDetailsModal,
    openSubscriptionStatusModal,
    setOpenSubscriptionStatusModal,
    details,
    setDetails,
    handleAddNewTaskToDb,
    handleAddTemplate,
    openPolicyModal,
    setOpenPolicyModal,
    policyItem,
    setPolicyItem,
    filters,
    setFilters,
    isFilterModalOpen,
    setIsFilterModalOpen,
    handleFilterChange,
    searchCptCode,
    filterOptions,
    localityInformationModal,
    setLocalityInformationModal,
    localityInformation,
    setLocalityInformation,
  } = useContext(ModalContext);

  const sortedOrganizations = [...organizations].sort((a, b) =>
    a.organizationName.localeCompare(b.organizationName)
  );

  return (
    <>
      <NewTaskModal
        open={openNewTaskModal}
        setOpen={setOpenNewTaskModal}
        handleAddItemToDb={handleAddNewTaskToDb}
      />
      <NewTemplateModal
        open={openNewTemplateModal}
        setOpen={setOpenNewTemplateModal}
        handleAddTemplate={handleAddTemplate}
      />

      <DetailsModal
        open={openDetailsModal}
        setOpen={setOpenDetailsModal}
        details={details}
        setDetails={setDetails}
      />

      <SubscriptionStatusModal
        open={openSubscriptionStatusModal}
        setOpen={setOpenSubscriptionStatusModal}
      />

      <PdfModal open={openPdfModal} setOpen={setOpenPdfModal} />

      <PdfViewer
        open={openPdfViewer}
        setOpen={setOpenPdfViewer}
        contractModalPdfDetails={contractModalPdfDetails}
        setContractModalPdfDetails={setContractModalPdfDetails}
      />
      <PolicyModal
        open={openPolicyModal}
        setOpen={setOpenPolicyModal}
        policyItem={policyItem}
      />
      <LocalityDescriptionModal
        localityInformationModal={localityInformationModal}
        setLocalityInformationModal={setLocalityInformationModal}
        localityInformation={localityInformation}
      />
      <FilterModal
        filters={filters}
        setFilters={setFilters}
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        handleFilterChange={handleFilterChange}
        // TODO: @precious why not just grab these from the context inside the filter modal?
        searchCptCode={searchCptCode}
        filterOptions={filterOptions}
      />
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  {/* <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  /> */}
                  <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                    Recon OS
                  </h1>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <Navigation navigationItems={navigationWithCurrentPage} />
                    {/* <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigationWithCurrentPage.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={classNames(
                                  item.current
                                    ? "text-indigo-600"
                                    : "text-gray-400 group-hover:text-indigo-600",
                                  "h-6 w-6 shrink-0"
                                )}
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li> */}
                    {organization && (
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Organization
                        </div>
                        <Listbox
                          value={selected}
                          disabled={
                            userInformation.email !== "chester@recon.health"
                              ? true
                              : false
                          }
                          onChange={async (selectedValue) => {
                            console.log("THE SELECTED VALUE", selectedValue);
                            // Perform your update logic here, for example:
                            updateUserOrganizationId(selectedValue.id);
                            setSelected(selectedValue);
                          }}
                        >
                          <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <li>
                                <a
                                  href="#"
                                  className="group flex gap-x-3 rounded-md text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                                >
                                  <span
                                    className={
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600"
                                    }
                                  >
                                    {selected.initals}
                                  </span>
                                  <span className="truncate">
                                    {selected.organizationName}
                                  </span>
                                </a>
                              </li>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400"
                                />
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 top-3 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {sortedOrganizations.map((eachOrganization) => (
                                <ListboxOption
                                  key={eachOrganization.id}
                                  value={eachOrganization}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                >
                                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                    {eachOrganization.organizationName}
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                        {/* <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul> */}
                      </li>
                    )}

                    <li className="mt-auto">
                      <Menu as="div" className="relative">
                        <MenuButton className="-m-1.5 flex items-center p-1.5">
                          <a
                            href="#"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              aria-hidden="true"
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            />
                            Settings
                          </a>
                        </MenuButton>
                        <DropdownMenu
                          items={userNavigation}
                          onItemClick={handleItemClick}
                          className="absolute right-0 z-10 mt-2.5 w-full bottom-12 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in "
                        />
                      </Menu>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}

        {loading ? (
          <SidebarSkeletonLoader />
        ) : (
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                {/* <img
          alt="Your Company"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          className="h-8 w-auto"
        /> */}
                <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                  Recon OS
                </h1>
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <Navigation navigationItems={navigationWithCurrentPage} />
                  {/* <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigationWithCurrentPage.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={classNames(
                                item.current
                                  ? "text-indigo-600"
                                  : "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li> */}
                  {organization && (
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Organization
                      </div>

                      <Listbox
                        value={selected}
                        disabled={
                          userInformation.email !== "chester@recon.health"
                            ? true
                            : false
                        }
                        onChange={async (selectedValue) => {
                          console.log("THE SELECTED VALUE", selectedValue);
                          // Perform your update logic here, for example:
                          updateUserOrganizationId(selectedValue.id);
                          setSelected(selectedValue);
                        }}
                      >
                        <div className="relative mt-2">
                          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <li>
                              <a
                                href="#"
                                className="group flex gap-x-3 rounded-md text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                              >
                                <span
                                  className={
                                    "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600"
                                  }
                                >
                                  {selected.initals}
                                </span>
                                <span className="truncate">
                                  {selected.organizationName}
                                </span>
                              </a>
                            </li>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                              />
                            </span>
                          </ListboxButton>

                          <ListboxOptions
                            transition
                            className="absolute z-10 top-3 mt-[2.5rem] max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                          >
                            {sortedOrganizations.map((eachOrganization) => (
                              <ListboxOption
                                key={eachOrganization.id}
                                value={eachOrganization}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                              >
                                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                  {eachOrganization.organizationName}
                                </span>
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </div>
                      </Listbox>
                      {/* <ul role="list" className="-mx-2 mt-2 space-y-1">
                        <li>
                          <a
                            href="#"
                            className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <span
                              className={
                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600"
                              }
                            >
                              H
                            </span>
                            <span className="truncate">
                              {organization.organizationName}
                            </span>
                          </a>
                        </li>
                      </ul> */}
                    </li>
                  )}

                  <li className="mt-auto">
                    <Menu as="div" className="relative">
                      <MenuButton className="-m-1.5 flex items-center p-1.5">
                        <a
                          href="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <Cog6ToothIcon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                          />
                          Settings
                        </a>
                      </MenuButton>
                      <DropdownMenu
                        items={userNavigation}
                        onItemClick={handleItemClick}
                        className="absolute right-0 z-10 mt-2.5 w-full bottom-12 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in "
                      />
                    </Menu>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-200 lg:hidden"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                />
                <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Ask anything..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  // disabled={true}
                  onClick={() => {
                    setOpenSubscriptionStatusModal(true);
                  }}
                />
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Separator */}
                <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    {userInformation?.email ? (
                      <EmailAvatar email={userInformation?.email} />
                    ) : (
                      <svg
                        className={`text-gray-300 bg-gray-100 rounded-full `}
                        width={30}
                        height={30}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="20"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 18a4 4 0 100-8 4 4 0 000 8zm0 2c-2.67 0-8 1.34-8 4v1a1 1 0 001 1h14a1 1 0 001-1v-1c0-2.66-5.33-4-8-4z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    )}

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-3 text-sm font-semibold truncate leading-6 text-gray-900"
                      >
                        {userInformation?.email}
                      </span>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-2 h-5 w-5 text-gray-400"
                      />
                    </span>
                  </MenuButton>

                  <DropdownMenu
                    items={userNavigation}
                    onItemClick={handleItemClick}
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in "
                  />
                </Menu>
              </div>
            </div>
          </div>

          <main className="pb-5">
            {/* <div className="px-4 sm:px-6 lg:px-8">
              Your content
            </div> */}
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
