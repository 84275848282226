export const EmailAvatar = ({ email }) => {
  const initial = email ? email[0].toUpperCase() : "NA";
  const colors = [
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-red-500",
    "bg-purple-500",
  ];
  const colorIndex = email ? email.length % colors.length : 0;

  return (
    <div
      className={`${colors[colorIndex]} rounded-full w-[40px] h-[40px] flex items-center justify-center`}
    >
      <span className="text-white text-lg font-bold">{initial}</span>
    </div>
  );
};
