import React, { useContext } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { CoPilotContext } from "../../context/co-pilot-context";
import { ModalContext } from "../../context/modal-context";
import { useNavigate } from "react-router-dom";
import { ReconActionButtons } from "../ReconActionBtns";

const Table = ({
  headers,
  data,
  columns,
  currentPage,
  onPageChange,
  totalPages,
  isNextDisabled,
  type,
}) => {
  const navigate = useNavigate();
  const { setUseParams, setUserQuery, updateLocalStorage } =
    useContext(CoPilotContext);
  const { openPolicyModal, setOpenPolicyModal, policyItem, setPolicyItem } =
    useContext(ModalContext);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleModalTrigger = (item) => {
    if (type === "denial") {
      if (item.payer || item.denialCode) {
        console.log("item payer", item.payer);
        console.log("item code", item.denialCode);
        setPolicyItem(item);
        setOpenPolicyModal(true);
      }
    }
  };
  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data.map((item, index) => (
                <tr
                  key={item.id}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className={column.className}
                      onClick={() => {
                        if (column.payer || column.denialCode) {
                          handleModalTrigger(item);
                        }
                      }}
                    >
                      {column?.status ? (
                        <span className={column?.renderStatusStyle(item)}>
                          {column.render(item)}
                        </span>
                      ) : column?.copilot ? (
                        <>
                          <ReconActionButtons title="Run" item={item} />
                        </>
                      ) : (
                        column.render(item)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-b-lg">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing page <span className="font-medium">{currentPage}</span>
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={isNextDisabled}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
