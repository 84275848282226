import { useState, useEffect } from "react";
import { neon } from "@neondatabase/serverless";

const DATABASE_URL =
  "postgres://neondb_owner:9pT6IAZsboUa@ep-odd-frost-a5v7m5i7.us-east-2.aws.neon.tech:5432/neondb?sslmode=require";

export const useProcedure = (cptCode) => {
  const [procedure, setProcedure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProcedure = async () => {
      if (!cptCode) return;

      setLoading(true);
      setError(null);

      try {
        const sql = neon(DATABASE_URL);
        const res = await sql`
          SELECT cpt_code, label
          FROM procedure
          WHERE cpt_code = ${cptCode}
        `;

        // Assuming that cpt_code is unique and only one record will be returned
        const procedureData = res[0] || null;
        setProcedure(procedureData);
      } catch (error) {
        console.error("Error executing query", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcedure();
  }, [cptCode]);

  return { procedure, loading, error };
};
