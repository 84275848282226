import React, { createContext, useState, useEffect } from "react";

export const CoPilotContext = createContext();

export const CoPilotContextProvider = ({ children }) => {
  const [useParams, setUseParams] = useState(
    JSON.parse(localStorage.getItem("useParams")) || false
  );
  const [userQuery, setUserQuery] = useState(
    localStorage.getItem("userQuery") || ""
  );

  const [languageModel, setLanguageModel] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [documentType, setDocumentType] = useState(null);

  useEffect(() => {
    localStorage.setItem("useParams", JSON.stringify(useParams));
  }, [useParams]);

  useEffect(() => {
    localStorage.setItem("userQuery", userQuery);
  }, [userQuery]);

  const updateLocalStorage = (key, value) => {
    if (key === "useParams") {
      setUseParams(value);
    } else if (key === "userQuery") {
      setUserQuery(value);
    }
    // Add more keys if needed
  };

  useEffect(() => {
    // Create a function to parse query parameters and update state
    const updateStateFromQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const documentType = queryParams.get("document_type");
      if (documentType) {
        setDocumentType(documentType);
      } else {
        setDocumentType(null);
      }
    };

    // Call the function to update state from query parameters
    updateStateFromQueryParams();

    // Add an event listener for the 'popstate' event to handle back/forward navigation
    window.addEventListener("popstate", updateStateFromQueryParams);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", updateStateFromQueryParams);
    };
  }, []);

  return (
    <CoPilotContext.Provider
      value={{
        useParams,
        setUseParams,
        userQuery,
        setUserQuery,
        languageModel,
        setLanguageModel,
        answerType,
        setAnswerType,
        documentType,
        setDocumentType,
        updateLocalStorage,
      }}
    >
      {children}
    </CoPilotContext.Provider>
  );
};
