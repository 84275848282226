"use client";

import { useEffect, useState } from "react";
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/24/solid";

export const DetailsModal = (props) => {
  const { open, setOpen, details, setDetails } = props;
  const [showNewTaskView, setShowNewTask] = useState(false);
  const [detailsData, setDetailsData] = useState(null);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  async function getDocumentFromCollection() {
    try {
      // Create a reference to the document
      const docRef = doc(db, "Phone agent details", details?.detailsId);

      // Get the document
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (docSnap.exists()) {
        // Document data can be retrieved with .data() method
        setDetailsData(docSnap.data());
      } else {
        // Document not found
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      // Handle any errors
      console.error("Error getting document:", error);
      return null;
    }
  }

  useEffect(() => {
    if (details?.detailsId) {
      getDocumentFromCollection();
    }
  }, [details?.detailsId]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setDetailsData(null);
      }}
      className="relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={classNames(
              showNewTaskView
                ? " lg:p-[2rem] lg:max-w-lg "
                : " lg:p-[2rem] lg:max-w-[48rem] ",
              "relative transform overflow-hidden rounded-lg bg-white    px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6   data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            )}
          >
            <div className=" sm:space-y-[2rem] lg:space-y-[3rem]">
              <DialogTitle
                as="h3"
                className="text-base font-semibold text-left leading-6 text-gray-900"
              >
                Phone Agent Information
              </DialogTitle>
            </div>

            <div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Phone Number
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {details?.phoneNumber}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Template
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {details?.template}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Progress
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <span
                        className={classNames(
                          "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                          details?.progress === "In Progress"
                            ? "bg-yellow-100 text-yellow-800"
                            : details?.progress === "Complete"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        )}
                      >
                        {details?.progress}
                      </span>
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Notes
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {details?.notes}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Contact Information
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {details?.contactInfo}
                    </dd>
                  </div>
                  {detailsData && (
                    <>
                      {/* Insurance Verification */}

                      {details?.template === "Prior Auth" && (
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Procedure Name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.procedureName}
                          </dd>
                        </div>
                      )}

                      {details?.template?.toLowerCase() ===
                        "insurance verification" && (
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Plan Active
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.planActive}
                          </dd>
                        </div>
                      )}

                      {details?.template === "Prior Auth" && (
                        <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Insurance Plan Name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.insurancePlanName}
                          </dd>
                        </div>
                      )}

                      {details?.template?.toLowerCase() ===
                        "insurance verification" && (
                        <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Insurance Plan Name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.insurancePlanName}
                          </dd>
                        </div>
                      )}

                      {details?.template === "Prior Auth" && (
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Insurance Number
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.insuranceNumber}
                          </dd>
                        </div>
                      )}

                      {details?.template?.toLowerCase() ===
                        "insurance verification" && (
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Patient Name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.patientName}
                          </dd>
                        </div>
                      )}

                      {details?.template === "Prior Auth" && (
                        <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Follow up Steps
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.followUpSteps}
                          </dd>
                        </div>
                      )}

                      {details?.template?.toLowerCase() ===
                        "insurance verification" && (
                        <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Employer
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {detailsData?.employer}
                          </dd>
                        </div>
                      )}
                    </>
                  )}
                </dl>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
