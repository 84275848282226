import React, { useEffect, useState } from "react";

import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";
import { LineChart } from "@mui/x-charts/LineChart";

import Table from "../../components/CustomizeTable";
import { TableCount } from "../../components/TableCount";

const itemsPerPage = 8;

const deniedClaims = [
  {
    claim: "13531",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "23532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "83533",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13634",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "18332",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/24/2024",
    amount: "$9210.24",
    denialReceived: "6/14/2024",
    procedure: "99231",
    denialCode: "50",
    remarkCode: "-",
    payer: "Aetna",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12142",
    dos: "5/22/2024",
    amount: "$1902.12",
    denialReceived: "6/12/2024",
    procedure: "99140",
    denialCode: "29",
    remarkCode: "-",
    payer: "UHC",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "13532",
    dos: "5/14/2024",
    amount: "$515.39",
    denialReceived: "6/4/2024",
    procedure: "73120",
    denialCode: "181",
    remarkCode: "M20",
    payer: "Blue Shield CA",
    status: "Recovered",
    copilot: "Run",
  },
  {
    claim: "12315",
    dos: "4/30/2024",
    amount: "$363.41",
    denialReceived: "5/21/2024",
    procedure: "99231",
    denialCode: "119",
    remarkCode: "M86",
    payer: "Coventry",
    status: "Ready To Start",
    copilot: "Run",
  },
  {
    claim: "12234",
    dos: "4/25/2024",
    amount: "$108.3",
    denialReceived: "5/16/2024",
    procedure: "73130",
    denialCode: "16",
    remarkCode: "-",
    payer: "Tricare",
    status: "Appeal Submitted",
    copilot: "Run",
  },
  {
    claim: "12152",
    dos: "4/10/2024",
    amount: "$1592.29",
    denialReceived: "5/1/2024",
    procedure: "24344",
    denialCode: "50",
    remarkCode: "M127",
    payer: "Anthem",
    status: "Ready To Start",
    copilot: "Run",
  },

  {
    claim: "11224",
    dos: "4/2/2024",
    amount: "$709.01",
    denialReceived: "4/23/2024",
    procedure: "24006",
    denialCode: "29",
    remarkCode: "N211",
    payer: "Oscar",
    status: "Recovered",
    copilot: "Run",
  },
];

const policiesHeaders = [
  "Claim ID",
  "DOS",
  "Amount",
  "Denial Received",
  "Procedure (CPT/ICD)",
  "Denial Code",
  "Remark Code",
  "Payer",
  "Status",
  "Copilot",
];

const columns = [
  {
    render: (item) => item.claim,
    className:
      "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-white bg-gray-700 sm:pl-6",
  },
  {
    render: (item) => item.dos,
    className:
      "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.amount,
    className:
      "whitespace-nowrap  text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.denialReceived,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.procedure,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.denialCode,
    denialCode: true,
    className:
      "whitespace-nowrap cursor-pointer text-center underline text-[#2e89d9] py-4 pl-4 pr-3 text-sm font-medium sm:pl-6",
  },
  {
    render: (item) => item.remarkCode,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.payer,
    payer: true,
    className:
      "whitespace-nowrap text-center underline  cursor-pointer  py-4 pl-4 pr-3 text-sm font-medium text-[#2e89d9] sm:pl-6",
  },
  {
    render: (item) => item.status,
    status: true,
    renderStatusStyle: (item) =>
      item.status === "Ready To Start"
        ? "inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-yellow-100 text-yellow-800"
        : item.status === "Recovered"
        ? "inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800"
        : "inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800",
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.copilot,
    copilot: true,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
];

const insurerColumns = [
  {
    render: (item) => item.insurance,
    className:
      "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-white bg-gray-700 sm:pl-6",
  },
  {
    render: (item) => item.cptCode,
    className:
      "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.billType,
    className:
      "whitespace-nowrap  text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.underPaid,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => item.count,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-yellow-900 sm:pl-6",
  },
  {
    render: (item) => item.average,
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
  },
  {
    render: (item) => (
      <span className={getPercentageColor(item.percentage)}>
        {item.percentage}
      </span>
    ),
    className:
      "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium sm:pl-6",
  },
];

const insurerHeaders = [
  "Insurance",
  "CPT",
  "Bill Type",
  "Denied Amt.",
  "Count",
  "Average Amt./Claim",
  "% Denied",
];
const insurerData = [
  {
    insurance: "Blue Cross",
    cptCode: "99214",
    billType: "Profee",
    underPaid: "$192,005.34",
    count: "1153",
    average: "$79.80",
    percentage: "1.12%",
  },
  {
    insurance: "Cigna",
    cptCode: "99214",
    billType: "Profee",
    underPaid: "$199,978.52",
    count: "1053",
    average: "$59.80",
    percentage: "1.2%",
  },
  {
    insurance: "Healthy Blue",
    cptCode: "99214",
    billType: "Profee",
    underPaid: "$101,423.68",
    count: "1100",
    average: "$70.80",
    percentage: "1.12%",
  },
  {
    insurance: "Healthy Blue",
    cptCode: "99213",
    billType: "Profee",
    underPaid: "$150,249.41",
    count: "980",
    average: "$50.80",
    percentage: "9.1%",
  },
  {
    insurance: "Blue Cross",
    cptCode: "99213",
    billType: "Profee",
    underPaid: "$142,203.56",
    count: "920",
    average: "$46.80",
    percentage: "8.4%",
  },
  {
    insurance: "United Healthcare",
    cptCode: "99214",
    billType: "Profee",
    underPaid: "$42,203.56",
    count: "397",
    average: "$82.21",
    percentage: "4.32%",
  },
  {
    insurance: "United Healthcare",
    cptCode: "99213",
    billType: "Profee",
    underPaid: "$31,641.12",
    count: "559",
    average: "$56.60",
    percentage: "4.18%",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getPercentageColor = (percentageString) => {
  // Remove the % sign and parse the string to a float
  const numericPercentage = parseFloat(percentageString.replace("%", ""));

  if (numericPercentage > 5) return "text-red-600";
  if (numericPercentage >= 3 && numericPercentage <= 5)
    return "text-yellow-600";
  return "text-green-600";
};

const generateRandomData = (startDate, endDate, minAmount, maxAmount) => {
  const data = [];
  let currentDate = new Date(startDate.getTime());

  while (currentDate <= endDate) {
    const randomAmount = Math.floor(
      Math.random() * (maxAmount - minAmount + 1) + minAmount
    );
    const estimatedReimbursement = randomAmount * 0.8; // Example calculation

    data.push({
      name: currentDate.toLocaleDateString("en-US"),
      Amount: randomAmount,
    });

    currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
  }

  return data;
};

export default function PaymentVariances() {
  // Calculate the date range: today and 30 days back
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 30);

  // Generate new data with specified date range and random amounts
  const newData = generateRandomData(startDate, endDate, 0, 12500);

  // const uData = newData.map((item) => item["Estimated Reimbursement"]);
  const pData = newData.map((item) => item.Amount);
  const xLabels = newData.map((item) => item.name);

  const [paymentVariance, setPaymentVariance] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("");
  const [tabs, setTabs] = useState([
    {
      name: "Individual Denials",
      href: "#",
      icon: UserIcon,
      current: true,
    },
    {
      name: "Aggregated By Insurer",
      href: "#",
      icon: BuildingOfficeIcon,
      current: false,
    },
    { name: "Insights", href: "#", icon: BuildingOfficeIcon, current: false },
  ]);

  const setCurrentTab = (tabName) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === tabName,
      }))
    );
  };

  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(paymentVariance.length / itemsPerPage);

  const currentData = paymentVariance.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const searchItem = [
    {
      field: "Procedure",
      value: "procedure",
    },
    {
      field: "Payer",
      value: "payer",
    },
    {
      field: "Denial Code",
      value: "denialCode",
    },
    {
      field: "Status",
      value: "status",
    },
  ];

  const insurerSearchTerms = [
    {
      field: "Insurance",
      value: "insurance",
    },
    {
      field: "CPT",
      value: "cptCode",
    },
    {
      field: "Bill Type",
      value: "billType",
    },
    {
      field: "Count",
      value: "count",
    },
  ];

  const isInsuranceTab = tabs.find(
    (tab) => tab.current && tab.name === "Aggregated By Insurer"
  );

  const searchPolicies = (term, field) => {
    if (term.trim() === "") {
      if (isInsuranceTab) {
        return insurerData;
      }

      return deniedClaims;
    }

    const lowerCaseTerm = term.toLowerCase();
    if (isInsuranceTab) {
      return insurerData.filter((policy) => {
        const fieldValue = policy[field]?.toString().toLowerCase();
        return fieldValue && fieldValue.includes(lowerCaseTerm);
      });
    }
    return deniedClaims.filter((policy) => {
      const fieldValue = policy[field]?.toString().toLowerCase();
      return fieldValue && fieldValue.includes(lowerCaseTerm);
    });
  };

  const filteredPolicies = searchPolicies(searchTerm, searchField);

  const selectedSearchItem = isInsuranceTab ? insurerSearchTerms : searchItem;

  useEffect(() => {
    setSearchTerm("");
    if (isInsuranceTab) {
      setSearchField("insurance");
    } else {
      setSearchField("procedure");
    }
  }, [tabs]);

  const tableItems = filteredPolicies?.length;

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 overflow-hidden bg-gray-100">
      <div className="sm:hidden bg-white">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current).name}
          // onChange={()}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-white">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => handleTabClick(tab.name)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                <tab.icon
                  aria-hidden="true"
                  className={classNames(
                    tab.current
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>

      {tabs.find(
        (tab) =>
          (tab.current && tab.name === "Aggregated By Insurer") ||
          (tab.current && tab.name === "Individual Denials")
      ) && (
        <div className="flex mt-4 flex-row justify-between items-end space-x-4 mb-4 ">
          <div className="flex flex-row  items-end space-x-4 ">
            <div className="relative flex  ">
              <input
                id="search"
                name="search"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div>
              <select
                id="location"
                name="location"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {selectedSearchItem?.map((item, indexId) => {
                  return (
                    <option value={item.value} key={indexId}>
                      {item.field}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="min-h-screen mt-[2rem] bg-gray-100 overflow-hidden">
        <div className=" py-4">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-gray-900">Denials</h1>
            </div>
          </div>
        </div>
        <>
          {/* {tabs.find(
            (tab) =>
              (tab.current && tab.name === "Aggregated By Insurer") ||
              (tab.current && tab.name === "Individual Denials")
          ) && <TableCount tableItems={tableItems} />} */}
          {tabs.find(
            (tab) => tab.current && tab.name === "Individual Denials"
          ) && (
            <Table
              headers={policiesHeaders}
              data={filteredPolicies}
              columns={columns}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              totalPages={totalPages}
              type={"denial"}
              isNextDisabled={true}
            />
          )}

          {tabs.find(
            (tab) => tab.current && tab.name === "Aggregated By Insurer"
          ) && (
            <Table
              headers={insurerHeaders}
              data={filteredPolicies}
              columns={insurerColumns}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              totalPages={totalPages}
              isNextDisabled={true}
            />
          )}
        </>

        {tabs.find((tab) => tab.current && tab.name === "Insights") && (
          <>
            <LineChart
              className="w-full pl-12 bg-white"
              height={400}
              xAxis={[{ data: xLabels, scaleType: "band" }]}
              series={[
                {
                  data: pData,
                  label: "Denied Amounts",
                },
              ]}
              // width={500}
              // height={300}
            />
          </>
        )}
      </div>
    </div>
  );
}
