import { useContext } from "react";
import { CoPilotContext } from "../../context/co-pilot-context";

export default function Chat() {
  const {
    useParams,
    userQuery,
    languageModel,
    setLanguageModel,
    answerType,
    setAnswerType,
    documentType,
  } = useContext(CoPilotContext);
  // Construct query parameters based on conditions
  const queryParams = [];
  if (useParams) {
    if (userQuery) {
      queryParams.push(`user_query=${encodeURIComponent(userQuery)}`);
    }
    if (documentType) {
      queryParams.push(`document_type=${encodeURIComponent(documentType)}`);
    }
  }
  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  const url = `https://rag-test-1-8f5a.onrender.com/${queryString}`;
  console.log("THE URL", url);
  return (
    <>
      <iframe
        src={url} // Replace with the URL you want to iframe
        width="100%"
        height="1200"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </>
  );
}
