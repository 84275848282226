import React, { useEffect, useState, useContext } from "react";
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { ModalContext } from "../../context/modal-context";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AiPhoneAgentPage = () => {
  const phoneAgentTableHeader = [
    "Phone Number",
    "Template",
    "Progress",
    "Notes",
    "Contact Info",
    "Recording",
    "Details",
  ];

  const templateTableHeader = ["Template Name", "Template Fields"];

  const {
    openNewTaskModal,
    setOpenNewTaskModal,
    handleAddNewTaskToDb,
    openNewTemplateModal,
    setOpenNewTemplateModal,
    openDetailsModal,
    setOpenDetailsModal,
    setDetails,
  } = useContext(ModalContext);

  const [phoneAgentDataState, setPhoneAgentDataState] = useState([]);
  const [templateDataState, setTemplateDataState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "Phone Tasks", href: "#", icon: UserIcon, current: true },
    { name: "Template", href: "#", icon: BuildingOfficeIcon, current: false },
  ]);

  const setCurrentTab = (tabName) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === tabName,
      }))
    );
  };
  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  const getPhoneAgents = async (isNextPage = false) => {
    let q;
    if (isNextPage && lastVisible) {
      q = query(
        collection(db, "Phone agent"),
        orderBy("phoneNumber"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      q = query(
        collection(db, "Phone agent"),
        orderBy("phoneNumber"),
        limit(itemsPerPage)
      );
    }

    const snapshot = await getDocs(q);
    const phoneAgentData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (phoneAgentData.length < itemsPerPage) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    }

    setPhoneAgentDataState(phoneAgentData);
  };

  const getTemplates = async () => {
    let q = query(
      collection(db, "template"),
      orderBy("createdAt"),
      limit(itemsPerPage)
    );

    const snapshot = await getDocs(q);
    const templateData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setTemplateDataState(templateData);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // const handleAddItemToDb = async (phoneNumber, template, notes) => {
  //   try {
  //     const item = {
  //       template: template,
  //       phoneNumber: phoneNumber,
  //       notes: notes,
  //       progress: "In Progress",
  //       contactInfo: "Blue Cross of Maryland",
  //     };
  //     await addDoc(collection(db, "Phone agent"), item);
  //     getPhoneAgents(); // Refresh the list after adding a new item
  //     console.log("Item added to the database");
  //   } catch (error) {
  //     console.error("Error adding document: ", error);
  //   }
  // };

  const handleNextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage(currentPage + 1);
      getPhoneAgents(true);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getPhoneAgents();
    }
  };

  useEffect(() => {
    getPhoneAgents();
    getTemplates();
  }, []);

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 overflow-hidden bg-gray-100">
      <div className="sm:hidden bg-white">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current).name}
          // onChange={()}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-white">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => handleTabClick(tab.name)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                <tab.icon
                  aria-hidden="true"
                  className={classNames(
                    tab.current
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>

      <div className="min-h-screen bg-gray-100 overflow-hidden">
        {tabs.find((tab) => tab.current && tab.name === "Phone Tasks") && (
          <div className="min-h-screen bg-gray-100">
            <div className=" py-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Phone Agent Tasks
                  </h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                  <button
                    type="button"
                    onClick={() => setOpenNewTaskModal(true)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add New Call Task
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenNewTaskModal(true)}
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Batch Add Tasks (CSV)
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            {phoneAgentTableHeader.map((item, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="text-center py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {phoneAgentDataState.map((item, index) => (
                            <tr
                              key={item.id}
                              className={
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {item.phoneNumber}
                              </td>
                              <td className=" px-3 py-4 text-sm text-gray-500">
                                {item.template}
                              </td>
                              <td className=" whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span
                                  className={classNames(
                                    "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                    item.progress === "In Progress"
                                      ? "bg-yellow-100 text-yellow-800"
                                      : item.progress === "Complete"
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  )}
                                >
                                  {item.progress}
                                </span>
                              </td>
                              <td className="px-3 py-4 whitespace-pre-wrap text-sm text-gray-500 max-w-xs truncate">
                                {item.notes}
                              </td>
                              <td className=" whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                {item.contactInfo}
                              </td>

                              {/*  */}
                              <td className="px-3 py-4 text-sm text-gray-500 text-center">
                                <a
                                  href="data:audio/mpeg;base64,"
                                  download="call-record.mp3"
                                  className="text-indigo-600 underline hover:text-indigo-900 text-center"
                                >
                                  Download
                                </a>
                              </td>
                              <td
                                className=" px-3 py-4 whitespace-nowrap text-sm text-gray-500"
                                onClick={() => {
                                  setDetails({
                                    phoneNumber: item.phoneNumber,
                                    template: item.template,
                                    progress: item.progress,
                                    notes: item.notes,
                                    contactInfo: item.contactInfo,
                                    detailsId: item?.agentDetailsId
                                      ? item?.agentDetailsId
                                      : null,
                                  });
                                  setOpenDetailsModal(true);
                                }}
                              >
                                <a
                                  href="#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Open Details
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-b-lg">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={isNextDisabled}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing page{" "}
                      <span className="font-medium">{currentPage}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        onClick={handleNextPage}
                        disabled={isNextDisabled}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {tabs.find((tab) => tab.current && tab.name === "Template") && (
          <div className="min-h-screen bg-gray-100">
            <div className="px-4 sm:px-6 lg:px-8 py-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Templates
                  </h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                  <button
                    type="button"
                    onClick={() => setOpenNewTemplateModal(true)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    New Template
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            {templateTableHeader.map((item, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {templateDataState.map((item, index) => {
                            console.log("THIS ITEM", item);
                            return (
                              <tr
                                key={item.id}
                                className={
                                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item.templateTitle}
                                </td>
                                <td className=" px-3 py-4 text-sm text-gray-500">
                                  {item.templateFields
                                    ?.map((fieldItem) => fieldItem.fieldName)
                                    .join(", ")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-b-lg">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={isNextDisabled}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing page{" "}
                      <span className="font-medium">{currentPage}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        onClick={handleNextPage}
                        disabled={isNextDisabled}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiPhoneAgentPage;
