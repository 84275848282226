import { useState, useEffect } from "react";
import { query, where, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase-config"; // Import your Firebase config

export const useOrganization = (organizationId) => {
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const q = query(
          collection(db, "organizations"),
          where("id", "==", organizationId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const response = querySnapshot.docs[0].data();
          setOrganization(response);
        } else {
          setError("No such organization!");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchOrganization();
    } else {
    }
  }, [organizationId]);

  return { organization, loading, error };
};
