import { useState, useEffect } from "react";
import {
  query,
  where,
  getDocs,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

import { db } from "../firebase-config"; // Import your Firebase config

export const useUser = () => {
  const auth = getAuth();
  const [userInformation, setUserInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async (user) => {
      try {
        const q = query(collection(db, "users"), where("id", "==", user?.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const response = {
            ...querySnapshot.docs[0].data(),
            docId: querySnapshot.docs[0].id,
          };
          console.log("The response --->", response);
          setUserInformation(response);
        } else {
          setError("No such user!");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("USER", user);
      if (user) {
        fetchUser(user);
      } else {
        setUserInformation(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const updateUserOrganizationId = async (newOrganizationId) => {
    if (!userInformation || !userInformation.docId) {
      setError("No user to update");
      return;
    }

    try {
      setLoading(true);
      const userDocRef = doc(db, "users", userInformation.docId);
      await updateDoc(userDocRef, {
        organizationId: newOrganizationId,
      });
      setUserInformation({
        ...userInformation,
        organizationId: newOrganizationId,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  return { userInformation, loading, error, updateUserOrganizationId };
};
