import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Copy } from "lucide-react";

export const SubscriptionStatusModal = ({ open, setOpen }) => {
  const [emailCopied, setEmailCopied] = useState(false);
  const supportEmail = "chester@recon.health";

  const handleContactSupport = () => {
    // Try to open the default email client
    window.open(`mailto:${supportEmail}`, "_blank");

    // Fallback: Copy email to clipboard
    navigator.clipboard
      .writeText(supportEmail)
      .then(() => {
        setEmailCopied(true);
        setTimeout(() => setEmailCopied(false), 3000); // Reset after 3 seconds
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg lg:min-w-[30vw] lg:overflow-hidden bg-white text-center shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
            <div className="flex flex-col items-center justify-center h-full p-6">
              <h2 className="text-2xl font-semibold mb-4">Plan Limitation</h2>
              <p className="text-gray-600 mb-8">
                Your current plan doesn't include Recon Document Chat. To get
                access, please contact us.
              </p>
              <button
                type="button"
                onClick={handleContactSupport}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center"
              >
                {/* <Copy className="w-5 h-5 mr-2" /> */}
                {emailCopied ? "Email Copied!" : "Contact Support"}
              </button>
              {emailCopied && (
                <p className="mt-2 text-sm text-green-600">
                  Support email copied to clipboard!
                </p>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
