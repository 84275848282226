import { BarChart } from "@tremor/react";

const data = [
  {
    name: "6/3/2024",
    "Estimated Reimbursement": 1579.0,
    Amount: 1969,
  },
  {
    name: "6/10/2024",
    "Estimated Reimbursement": 3058.0,
    Amount: 3519,
  },
  {
    name: "6/17/2024",
    "Estimated Reimbursement": 3159.28,
    Amount: 3710,
  },
  {
    name: "6/24/2024",
    "Estimated Reimbursement": 2415.38,
    Amount: 3060,
  },
];

export const TremorChart = () => {
  function formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  const customTooltip = (props) => {
    const { payload, active } = props;
    if (!active || !payload) return null;
    console.log("THE PAYLOAD", payload);
    return (
      <div className=" rounded-lg border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown space-y-2">
        {payload.map((category, idx) => (
          <div
            key={idx}
            className="flex flex-1 flex-row items-center space-x-2"
          >
            <div
              className={`flex w-1 h-1 rounded-full flex-col bg-${category.color} rounded`}
            />
            <div className="space-y-1 ml-1">
              <p className="text-tremor-content text-sm text-left">
                {category.dataKey === "Estimated Reimbursement"
                  ? "Estimated Reimbursement"
                  : "Amount"}
              </p>
              <p className="font-medium text-left text-tremor-content-emphasis">
                {`$ ${formatCurrency(category.value)}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex py-4 flex-row overflow-x-auto">
      <BarChart
        className="mt-4 py-[1rem] overflow-x-scroll h-72 text-[12px]"
        data={data}
        index="name"
        stack={false}
        categories={["Estimated Reimbursement", "Amount"]}
        colors={["green-600", "green-300"]}
        // yAxisWidth={0}
        customTooltip={customTooltip}
      />
    </div>
  );
};
