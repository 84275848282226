import React from 'react';

export const ShimmerButton = ({ onClick, loading, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={loading}
      className="relative rounded-md mt-8 w-full px-3 py-2 text-sm font-semibold 
                 text-white bg-indigo-600 
                 hover:bg-indigo-500 focus-visible:outline 
                 focus-visible:outline-2 focus-visible:outline-offset-2 
                 focus-visible:outline-indigo-600 
                 overflow-hidden"
    >
      <span className="relative z-10">
        {loading ? 'Processing...' : children }
      </span>
      <div className="absolute top-0 left-0 w-full h-full 
                      bg-gradient-to-r from-transparent via-white to-transparent
                      animate-shimmer" 
           style={{
             backgroundSize: '200% 100%',
             animation: 'shimmer 2s infinite',
           }}
      />
    </button>
  );
};

// export default ShimmerButton;