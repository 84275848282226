export const TableContent = (row, title, setOpen) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  switch (title) {
    case "Contracts":
      return (
        <>
          <div className="font-medium text-gray-800">{row.payer}</div>
          <div className="text-gray-600">{row.contractEntity}</div>
          <div
            className="text-gray-600 underline cursor-pointer"
            onClick={() => setOpen(true)}
          >
            {row.effectiveDate}
          </div>
          <div
            className="text-gray-600 underline cursor-pointer"
            onClick={() => setOpen(true)}
          >
            {row.initialTerm}
          </div>
          <div className="text-gray-600">{row.renewalDate}</div>
          <div
            className="text-gray-600 underline cursor-pointer"
            onClick={() => setOpen(true)}
          >
            {row.notificationPeriod}
          </div>
          <div>
            <button
              onClick={() => setOpen(true)}
              className="bg-teal-600 hover:bg-teal-700 text-white text-xs font-medium py-1 px-3 rounded transition duration-300"
            >
              {row.contractDetails}
            </button>
          </div>
        </>
      );

    case "Filing Requirements":
      return (
        <>
          <div className="font-medium text-gray-800">{row.payer}</div>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600 underline cursor-pointer"
          >
            {row.claimSubmission}
          </div>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600 underline cursor-pointer"
          >
            {row.appeals}
          </div>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600 underline cursor-pointer"
          >
            {row.additionalInfo}
          </div>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600 underline cursor-pointer"
          >
            {row.refundRequest}
          </div>
          <div>
            <button
              onClick={() => setOpen(true)}
              className="bg-teal-600 hover:bg-teal-700 text-white text-xs font-medium py-1 px-3 rounded transition duration-300"
            >
              View Details
            </button>
          </div>
        </>
      );

    case "Contracted Rates":
      return (
        <>
          <div className="font-medium text-gray-800">{row.procedure}</div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {row.payer}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600  cursor-pointer"
          >
            {row.plan}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600  "
          >
            {row.locality}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {row.placeOfService}
          </div>

          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {row.providerType}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {row.npi}
          </div>

          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {row.rateEffectiveDate}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          >
            {`$${row.rate}`}
          </div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          ></div>
          <div
            // onClick={() => setOpen(true)}
            className="text-gray-600 "
          ></div>
        </>
      );

    case "Prior Authorization Policies":
      return (
        <>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600  cursor-pointer underline"
          >
            {row.procedure}
          </div>
          <div className="text-gray-600">{row.payer}</div>
          <div
            onClick={() => setOpen(true)}
            className="text-gray-600  underline cursor-pointer"
          >
            {row.priorAuthorization}
          </div>
          <div
            className={classNames(
              row?.notificationRequirement !== "n/a"
                ? "underline cursor-pointer"
                : "",
              "text-gray-600"
            )}
            onClick={() => {
              if (row?.notificationRequirement !== "n/a") {
                setOpen(true);
              }
            }}
          >
            {row.notificationRequirement}
          </div>
          <div
            className={classNames(
              row?.responseTime !== "n/a" ? "underline cursor-pointer" : "",
              "text-gray-600"
            )}
            onClick={() => {
              if (row?.responseTime !== "n/a") {
                setOpen(true);
              }
            }}
          >
            {row.responseTime}
          </div>
          <div
            className={classNames(
              row?.maxVisitsDuration !== "n/a"
                ? "underline cursor-pointer"
                : "",
              "text-gray-600"
            )}
            onClick={() => {
              if (row?.maxVisitsDuration !== "n/a") {
                setOpen(true);
              }
            }}
          >
            {row.maxVisitsDuration}
          </div>
          <div className="text-gray-600">{row.timeBetweenServices}</div>
          <div className="text-gray-600">
            <a
              href="https://app.novologix.net/login/login?ReturnUrl=%2fdefault.aspx"
              target="_blank"
              class="text-blue-600 underline"
            >
              Go to Site
            </a>
          </div>
        </>
      );

    case "Denials":
      return (
        <>
          <div className="font-medium text-gray-800">{row.claim}</div>
          <div className="text-gray-600">{row.dos}</div>
          <div className="text-gray-600">{row.amount}</div>
          <div className="text-gray-600">{row.denialReceived}</div>
          <div className="text-gray-600">{row.procedure}</div>
          <div className="text-gray-600">{row.denialCode}</div>
          <div className="text-gray-600">{row.remarkCode}</div>
          <div className="text-gray-600">{row.payer}</div>
          <div className="text-gray-600">
            <a href="/chat" class="text-blue-600 underline">
              {row.searchPolicies}
            </a>
          </div>
        </>
      );

    case "Payment Variances":
      return (
        <>
          <div className="font-medium text-gray-800">{row.claimId}</div>
          <div className="text-gray-600">{row.dateOfService}</div>
          <div className="text-gray-600">{row.payer}</div>
          <div className="text-gray-600">{row.contractedRate}</div>
          <div className="text-gray-600">{row.allowedAmount}</div>
          <div className="text-gray-600">{row.underpayment}</div>

          <div className="text-gray-600">
            {" "}
            <span
              className={classNames(
                "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                row.status === "Ready To Start"
                  ? "bg-yellow-100 text-yellow-800"
                  : row.status === "Recovered"
                  ? "bg-green-100 text-green-800"
                  : "bg-blue-100 text-blue-800"
              )}
            >
              {row.status}
            </span>
          </div>
        </>
      );

    default:
      break;
  }
};
