export const documentTypes = ["Contracts", "Policies", "Florida_blue"];

export const contractedRateFilterOptions = [
  { field: "Procedure", value: "procedure" },
  { field: "Payer", value: "payer" },
  { field: "Plan", value: "plan" },
  { field: "Locality", value: "locality" },
];

export const navigationItems = [
  { name: "Overview", id: 1, href: "overview" },
  {
    name: "Recon Copilot",
    id: 2,
    href: "chat",
  },
  {
    name: "Contracts",
    id: 3,
    href: "billing",
  },
  {
    name: "Contracted Rates",
    id: 4,
    href: "contracted-rates",
  },
  {
    name: "Filing Requirements",
    id: 5,
    href: "filing",
  },
  {
    name: "Prior Authorizations",
    id: 6,
    href: "prior-auth",
  },

  {
    name: "Denials",
    id: 7,
    href: "denials",
  },

  {
    name: "Payment Variances",
    id: 8,
    href: "payment-variances",
  },

  {
    name: "Patient Payments",
    id: 9,
    href: "patient-payments",
  },
  {
    name: "Recon Phone Agent",
    id: 10,
    href: "ai-phone-agent",
  },

  {
    name: "Test Tool",
    id: 11,
    href: "insight-engine",
  },

  {
    name: "Use Case",
    id: 12,
    href: "use-case",
  },

  {
    name: "Audit Result",
    id: 13,
    href: "audit-result",
  },

  {
    name: "Review Audit",
    id: 14,
    href: "review-audit",
  },
  {
    name: "Question Studio",
    id: 15,
    href: "question-studio",
  },
];
