import { query, where, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { navigationItems } from "./constants";

export const handleRedirection = async (uid, navigate) => {
  try {
    const q = query(
      collection(db, "organization_users"),
      where("user_id", "==", uid)
    );
    const querySnapshot = await getDocs(q);

    const user_query = query(collection(db, "users"), where("id", "==", uid));
    const user_query_snapshot = await getDocs(user_query);

    const user_data = user_query_snapshot.docs.map((doc) => doc.data());

    const organization_id = user_data[0].organizationId;

    if (!querySnapshot.empty) {
      const responseArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        docId: doc.id,
      }));

      // Find the default navigation tab for the user's organization
      // Find the organization in responseArray that matches the organization_id
      const matchingOrganization = responseArray.find(
        (org) => org.organization_id === organization_id
      );

      if (matchingOrganization) {
        // Use the matching organization's ID for the query
        const tabsQuery = query(
          collection(db, "organization_tabs"),
          where("organizationId", "==", matchingOrganization?.organization_id),
          where("is_default_navigation", "==", true)
        );

        const tabsSnapshot = await getDocs(tabsQuery);

        let defaultTab = null;
        if (!tabsSnapshot.empty) {
          defaultTab = tabsSnapshot.docs[0].data();
        }

        console.log("defaultTab --->", defaultTab);

        if (defaultTab) {
          // Find the matching navigation item
          const matchingNavItem = navigationItems.find(
            (item) => item.id === defaultTab.tabId
          );
          if (matchingNavItem) {
            // Redirect to the matching navigation item's href
            navigate(matchingNavItem.href);
            return; // Exit the function after redirecting
          } else {
            console.log("No matching navigation item found");
          }
        } else {
          console.log("No default navigation tab found for the organization");
        }
      } else {
        console.log("No matching organization found in responseArray");
      }
    }
  } catch (err) {
    console.log("Error --->", err);
  }
};

export const truncateText = (text, maxLength = 30) => {
  if (text?.length > maxLength) {
    return text.substr(0, maxLength - 3) + "...";
  }
  return text;
};
