import CustomBlockTable from "../../components/CustomBlockTable";
import React, { useEffect, useState } from "react";
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase-config";

export default function Billing() {
  const policiesHeaders = [
    "Payer",
    "Claim Submission",
    "Appeals",
    "Additional Info Request",
    "Refund Request",
    "Clean Claim Requirements",
  ];

  const [fillingRequirements, setFillingRequirements] = useState([]);

  const getFillingRequirements = async () => {
    const q = query(collection(db, "Filing requirements"));
    const snapshot = await getDocs(q);
    const paymentData = snapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    console.log("this paymentData notes", paymentData);
    setFillingRequirements(paymentData);
  };

  useEffect(() => {
    getFillingRequirements();
  }, []);

  return (
    <>
      <CustomBlockTable
        title={"Filing Requirements"}
        policiesContracts={fillingRequirements}
        policiesHeaders={policiesHeaders}
        col={true}
        colNumber="grid-cols-6"
      />
    </>
  );
}
