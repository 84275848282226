"use client";

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ArrowLeft } from "lucide-react";

const people = [
  { id: 1, name: "short text" },
  { id: 2, name: "number" },
  { id: 3, name: "note - long text" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TemplateFields = (props) => {
  const {
    setOpen,
    showNewTaskView,
    setShowNewTask,
    newTemplateTitle,
    setNewTemplateTitle,
    newTemplateDiscription,
    setNewTemplateDiscription,
    newTemplateScript,
    setNewTemplateScript,
    newTemplateFields,
    setNewTemplateFields,
    fieldName,
    setFieldName,
    fieldExample,
    setFieldExample,
    selected,
    setSelected,
    handleAddTemplate,
  } = props;

  const handleAddNewField = (fieldName, fieldExample) => {
    if (fieldName.length > 0) {
      const templateFieldItem = {
        fieldName: fieldName,
        fieldExample: fieldExample,
        fieldType: selected?.name,
      };
      setNewTemplateFields([...newTemplateFields, templateFieldItem]);
      setFieldName("");
      setFieldExample("");
    }
  };
  const removeField = (indexToRemove) => {
    setNewTemplateFields((currentFields) =>
      currentFields.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAdditionOfTemplate = async () => {
    const item = {
      templateTitle: newTemplateTitle,
      templateDescription: newTemplateDiscription,
      templateScript: newTemplateScript,
      templateFields: newTemplateFields,
      createdAt: new Date(),
    };
    if (newTemplateTitle.length > 0) {
      await handleAddTemplate(item);
      setNewTemplateTitle("");
      setNewTemplateDiscription("");
      setNewTemplateScript("");
      setNewTemplateFields([]);
      setOpen(false);
      setShowNewTask(false);
    } else {
      return;
    }
  };
  console.log("NEW FIELDS --->", newTemplateFields);
  return (
    <div
      className={classNames(
        showNewTaskView ? " " : "hidden ",
        "flex flex-col w-full sm:space-y-[2rem] lg:space-y-[2rem] py-[2rem]"
      )}
    >
      <DialogTitle
        as="h3"
        className="text-base font-semibold text-center leading-6 text-gray-900"
      >
        Add Info to Collect During Call
      </DialogTitle>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Field Name
        </label>
        <div className="mt-2">
          <input
            id="fieldName"
            type="text"
            placeholder="Prior Auth"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
          {newTemplateFields.length > 0 && (
            <div className="flex flex-row flex-wrap space-x-2  w-full  border rounded-lg mt-4 p-4 bg-gray-100">
              {newTemplateFields?.map((field, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center space-x-2 p-1 rounded-lg shadow-sm mt-1   bg-white"
                  >
                    <p className="text-sm font-medium font-news">
                      {field.fieldName}
                    </p>

                    <XMarkIcon
                      aria-hidden="true"
                      onClick={() => removeField(index)}
                      className="h-5 w-3 text-red-500 cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Field Example
        </label>
        <div className="">
          <input
            id="feildExample"
            type="text"
            placeholder=""
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={fieldExample}
            onChange={(e) => setFieldExample(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Listbox value={selected} onChange={setSelected}>
          <Label className="block text-sm font-medium leading-6 text-gray-900">
            Field type
          </Label>
          <div className="relative mt-2">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {people.map((person) => (
                <ListboxOption
                  key={person.id}
                  value={person}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                >
                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                    {person.name}
                  </span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>

        <div className="flex flex-row justify-end">
          <button
            type="button"
            onClick={() => {
              handleAddNewField(fieldName, fieldExample);
            }}
            className="inline-flex items-center mt-[1rem] gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            Add field
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-center space-x-4">
        <button
          type="button"
          onClick={() => setShowNewTask(false)}
          className="rounded-md mt-[2rem] items-center flex flex-row justify-center bg-indigo-600 w-full  space-x-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Back
        </button>

        <button
          type="button"
          onClick={async () => {
            await handleAdditionOfTemplate();
          }}
          className="rounded-md mt-[2rem] bg-indigo-600 w-full  space-x-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Complete Template
        </button>
      </div>
    </div>
  );
};

export const NewTemplateModal = (props) => {
  const { open, setOpen, handleAddTemplate } = props;
  const [showNewTaskView, setShowNewTask] = useState(false);

  const [newTemplateTitle, setNewTemplateTitle] = useState("");
  const [newTemplateDiscription, setNewTemplateDiscription] = useState("");
  const [newTemplateScript, setNewTemplateScript] = useState("");

  const [newTemplateFields, setNewTemplateFields] = useState([]);
  const [fieldName, setFieldName] = useState("");
  const [fieldExample, setFieldExample] = useState("");
  const [selected, setSelected] = useState(people[1]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={classNames(
              showNewTaskView
                ? " lg:p-[2rem] lg:max-w-lg "
                : " lg:p-[2rem] lg:max-w-lg ",
              "relative transform overflow-hidden rounded-lg bg-white    px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6   data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            )}
          >
            <TemplateFields
              setOpen={setOpen}
              newTemplateTitle={newTemplateTitle}
              setNewTemplateTitle={setNewTemplateTitle}
              newTemplateDiscription={newTemplateDiscription}
              setNewTemplateDiscription={setNewTemplateDiscription}
              newTemplateScript={newTemplateScript}
              setNewTemplateScript={setNewTemplateScript}
              newTemplateFields={newTemplateFields}
              setNewTemplateFields={setNewTemplateFields}
              setFieldName={setFieldName}
              fieldName={fieldName}
              fieldExample={fieldExample}
              setFieldExample={setFieldExample}
              selected={selected}
              setSelected={setSelected}
              showNewTaskView={showNewTaskView}
              setShowNewTask={setShowNewTask}
              handleAddTemplate={handleAddTemplate}
            />

            <div
              className={classNames(
                showNewTaskView ? "hidden " : " ",
                "flex flex-col w-full sm:space-y-[2rem] lg:space-y-[2rem] py-[2rem]"
              )}
            >
              <DialogTitle
                as="h3"
                className="text-base font-semibold text-center leading-6 text-gray-900"
              >
                New Template
              </DialogTitle>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Template Title
                </label>
                <div className="mt-2">
                  <input
                    id="phoneNumber"
                    type="text"
                    placeholder=""
                    value={newTemplateTitle}
                    onChange={(e) => setNewTemplateTitle(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Template Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={4}
                    value={newTemplateDiscription}
                    onChange={(e) => setNewTemplateDiscription(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Template Script
                </label>
                <div className="mt-2">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={4}
                    value={newTemplateScript}
                    onChange={(e) => setNewTemplateScript(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setShowNewTask(true)}
                  className="rounded-md mt-[2rem] bg-indigo-600 w-full  space-x-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Continue
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
