import React, { createContext,usecontext, useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import axios from "axios";
import { useUserContext } from "./user-context";
import { RECON_OS_SERVER_URI } from "@/lib/services";
import { db } from "../firebase-config";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const { userInformation } = useUserContext();
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [policyItem, setPolicyItem] = useState(false);
  const [startPage, setStartPage] = useState(1);
  const [type, setType] = useState("BSBC-1");
  const [contractModalPdfDetails, setContractModalPdfDetails] = useState();
  const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
  const [openNewTemplateModal, setOpenNewTemplateModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [details, setDetails] = useState();
  const [filterOptions, setFilterOptions] = useState({});
  const [openSubscriptionStatusModal, setOpenSubscriptionStatusModal] =
    useState(false);

  const [localityInformationModal, setLocalityInformationModal] =
    useState(false);
  const [localityInformation, setLocalityInformation] = useState({
    locality: "",
    description: "",
  });
  // CPT CODE SEARCH
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [cptSearchData, setCptSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: "",
    startDate: new Date().toISOString().split("T")[0], // Default to today's date
    endDate: "",
    plan: "",
    providerId: "",
    providerType: "",
    locality: "",
    carrier: "",
    payer: "",
    clause: "",
    feeSchedule: "",
  });

  console.log("Filters", filters);
  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD
    }
    return date; // Return as-is if it's already a string or undefined
  };

  const fetchCPTCodeData = async (
    cptCode,
    startDate = "",
    endDate = "",
    localityCode = "",
    providerType = null,
    carrier = null,
    payer = null,
    plan = null,
    clause = null,
    feeSchedule = null
  ) => {
    setIsLoading(true);
    const organizationId = userInformation?.organizationId; // Get organizationId from userInformation
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const response = await axios.post(
        `${RECON_OS_SERVER_URI}/cpt-price-search`,
        {
          cptCode,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          locality: localityCode,
          providerTypeId: providerType,
          providerType: providerType,
          carrier,
          payer,
          plan,
          clause,
          feeSchedule,
        },
        {
          params: {
            organization_id: organizationId, // Pass organizationId as a query parameter
          },
        }
      );
      const responseData = response.data;
      console.log("The response data is", responseData);
      setResults(responseData);
      return responseData;
    } catch (error) {
      console.log("Error fetching CPT code data:", error);
      setResults([]);
      throw error; // Propagate the error to the caller if needed
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterOptions = async () => {
    try {
      const response = await axios.get(`${RECON_OS_SERVER_URI}/filter-options`);
      console.log("The response data  for filter options is", response.data);
      setFilterOptions(response.data);
    } catch (error) {
      console.log("Error fetching filter options:", error);
      setFilterOptions({});
    }
  };

  const performDefaultSearch = async (organizationId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${RECON_OS_SERVER_URI}/default-cpt-price-search`,
        {
          params: {
            organization_id: organizationId, // Pass organizationId as a query parameter
          },
        }
      );
      // console.log("The response data is", response.data);
      setResults(response.data);
    } catch (error) {
      console.log("Error fetching default data:", error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewTaskToDb = async (phoneNumber, template, notes) => {
    try {
      const item = {
        template: template,
        phoneNumber: phoneNumber,
        notes: notes,
        progress: "In Progress",
        contactInfo: "Blue Cross of Maryland",
      };
      await addDoc(collection(db, "Phone agent"), item);
      // You might need to update state or perform other actions here
      console.log("Item added to the database");
    } catch (error) {
      console.log("Error adding document: ", error);
    }
  };

  const handleAddTemplate = async (item) => {
    try {
      await addDoc(collection(db, "template"), item);
      console.log("Template added to db");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Change to search
  const searchCptCode = async () => {
    console.log("Applying filters:", filters);

    try {
      await fetchCPTCodeData(
        filters.searchTerm,
        filters.startDate,
        filters.endDate,
        filters?.locality,
        filters?.providerType,
        filters?.carrier,
        filters?.payer,
        filters?.plan,
        filters?.clause,
        filters?.feeSchedule
      );
    } catch (error) {
      console.log("Error fetching CPT code data:", error);
      // setResults([]);
    }

    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    // Create a function to parse query parameters and update state
    const updateStateFromQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const openNewTask = queryParams.get("openNewTaskModal") === "true";
      setOpenNewTaskModal(openNewTask);
    };

    // Call the function to update state from query parameters
    updateStateFromQueryParams();

    fetchFilterOptions();

    // Add an event listener for the 'popstate' event to handle back/forward navigation
    window.addEventListener("popstate", updateStateFromQueryParams);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", updateStateFromQueryParams);
    };
  }, []);

  useEffect(() => {
    if (userInformation?.organizationId) {
      performDefaultSearch(userInformation?.organizationId);
    }
  }, [userInformation]);
  return (
    <ModalContext.Provider
      value={{
        openPdfModal,
        setOpenPdfModal,
        openPdfViewer,
        setOpenPdfViewer,
        openPolicyModal,
        setOpenPolicyModal,
        policyItem,
        setPolicyItem,
        startPage,
        setStartPage,
        type,
        setType,
        contractModalPdfDetails,
        setContractModalPdfDetails,
        openNewTaskModal,
        setOpenNewTaskModal,
        openNewTemplateModal,
        setOpenNewTemplateModal,
        openDetailsModal,
        setOpenDetailsModal,
        openSubscriptionStatusModal,
        setOpenSubscriptionStatusModal,
        details,
        setDetails,
        handleAddNewTaskToDb,
        handleAddTemplate,
        filters,
        setFilters,
        results,
        setResults,
        isFilterModalOpen,
        setIsFilterModalOpen,
        handleFilterChange,
        searchCptCode,
        filterOptions,
        setFilterOptions,
        localityInformationModal,
        setLocalityInformationModal,
        localityInformation,
        setLocalityInformation,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
