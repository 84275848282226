import React, { useState, useMemo, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { PlusIcon, BellIcon } from "@heroicons/react/24/outline";
import { questionStudioRowData as initialRowData } from "../../lib/dummy-data";
const tagColors = {
  "Select All": "bg-purple-600 text-white",
  "Behavioral Health": "bg-blue-100 text-blue-800",
  Data: "bg-green-100 text-green-800",
  Consultation: "bg-yellow-100 text-yellow-800",
  Hospice: "bg-red-100 text-red-800",
  "Clinical Quality": "bg-purple-100 text-purple-800",
  "Hospice Care": "bg-pink-100 text-pink-800",
  Neurology: "bg-indigo-100 text-indigo-800",
  "Home Health Care": "bg-orange-100 text-orange-800",
};

const QuestionStudio = () => {
  const [rowData, setRowData] = useState(initialRowData);
  const [activeFilters, setActiveFilters] = useState(["Select All"]);

  const [columnDefs] = useState([
    {
      field: "question",
      headerName: "Question",
      flex: 3,
      minWidth: 200,
      sortable: true,
      filter: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      minWidth: 150,
      cellRenderer: TagsRenderer,
      sortable: true,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Edit",
      cellRenderer: () => (
        <span className="text-purple-600 cursor-pointer">✎</span>
      ),
      width: 70,
    },
    {
      headerName: "In Set",
      cellRenderer: () => (
        <input type="checkbox" className="rounded text-purple-600" />
      ),
      width: 70,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  );

  const filterOptions = useMemo(
    () => [
      "Select All",
      ...Object.keys(tagColors).filter((tag) => tag !== "Select All"),
    ],
    []
  );

  const handleFilterClick = useCallback(
    (filter) => {
      if (filter === "Select All") {
        setActiveFilters(["Select All"]);
      } else {
        const newFilters = activeFilters.includes("Select All")
          ? [filter]
          : activeFilters.includes(filter)
          ? activeFilters.filter((f) => f !== filter)
          : [...activeFilters, filter];
        setActiveFilters(newFilters.length ? newFilters : ["Select All"]);
      }
    },
    [activeFilters]
  );

  // Apply filtering
  useEffect(() => {
    if (activeFilters.includes("Select All")) {
      setRowData(initialRowData);
    } else {
      const filteredData = initialRowData.filter((row) =>
        row.tags.some((tag) => activeFilters.includes(tag))
      );
      setRowData(filteredData);
    }
  }, [activeFilters]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <select className="border rounded-md py-2 pr-8 bg-white">
            <option>Select Question Set</option>
          </select>
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            Create
          </button>
        </div>
      </div>

      <div className="flex">
        <div className="w-[20%] pr-4">
          <h2 className="font-semibold mb-2">Filter Options</h2>
          <div className="space-y-2">
            {filterOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleFilterClick(option)}
                className={`cursor-pointer rounded-full px-3 py-2 text-sm font-medium ${
                  activeFilters.includes("Select All") ||
                  activeFilters.includes(option)
                    ? tagColors[option]
                    : "bg-gray-200 text-gray-700"
                } transition-colors duration-150`}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div className="w-3/4">
          <div
            className="ag-theme-quartz w-full h-full ag-theme-alpine "
            style={{
              height: 600,
              "--ag-row-border-width": "1px",
              "--ag-row-border-color": "#e2e8f0",
              "--ag-cell-horizontal-border": "solid 1px #e2e8f0",
              "--ag-font-size": "14px",
              "--ag-header-font-size": "18px",
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function TagsRenderer(props) {
  return (
    <div className="flex flex-wrap gap-1">
      {props.value &&
        props.value.map((tag, index) => (
          <span
            key={index}
            className={`rounded px-2 py-1 text-xs ${tagColors[tag]}`}
          >
            {tag}
          </span>
        ))}
    </div>
  );
}

export default QuestionStudio;
