"use client";

import { Fragment, useState, useEffect, useRef } from "react";
// import { ArrowUpIcon, ArrowDownIcon } from "lucide-react";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/20/solid";
import {
  BellIcon,
  XMarkIcon,
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  NewspaperIcon,
  BanknotesIcon,
  ExclamationCircleIcon,
  ArchiveBoxXMarkIcon,
  UsersIcon,
  CheckCircleIcon,
  ShieldExclamationIcon
} from "@heroicons/react/24/outline";

import Charts from "../../components/Chart";
import { TremorChart } from "../../components/TremorCharts";
import { SimpleBarChart } from "../../components/MuiChart";

const navigation = [
  { name: "Home", href: "#" },
  { name: "Invoices", href: "#" },
  { name: "Clients", href: "#" },
  { name: "Expenses", href: "#" },
];
const secondaryNavigation = [
  //   { name: `${(new Date()).toDateString()}`, href: '#', current: true },
  { name: "Last 90 days", href: "#", current: true },
  { name: "All-time", href: "#", current: false },
];

const financialStats = [
  {
    name: "Payments Posted",
    value: "1105091.00",
    change: "+4.75%",
    changeType: "positive",
  },
  {
    name: "Expected AR",
    value: "412787.00",
    change: "+54.02%",
    changeType: "negative",
  },
  {
    name: "Total Billed Charges",
    value: "1517878.00",
    change: "-1.39%",
    changeType: "positive",
  },
  //   { name: 'Expenses', value: '$30,156.00', change: '+10.18%', changeType: 'negative' },
];

// const moreStats = [
//   {
//     id: 1,
//     name: "Total Number of Contracts",
//     stat: "240",
//     icon: NewspaperIcon,
//     change: "+10",
//     changeType: "increase",
//   },
//   {
//     id: 2,
//     name: "Total Contracts Missing",
//     stat: "10",
//     icon: ExclamationCircleIcon,
//   },
//   {
//     id: 3,
//     name: "Upcoming Rate Change (<6mo)",
//     stat: "17",
//     icon: NewspaperIcon,
//   },
//   { id: 3, name: "Expired Contracts", stat: "15", icon: ArchiveBoxXMarkIcon },
// ];

const currentDate = new Date();
const formattedCurrentDate =
  `${currentDate.getMonth() + 1}`.padStart(2, "0") +
  `/${currentDate.getDate()}`.padStart(2, "0") +
  `/${currentDate.getFullYear()}`;

function getFormattedTimeFromNow(hours) {
  const date = new Date();
  date.setHours(date.getHours() - hours);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: true,
  };

  return date.toLocaleTimeString("en-US", options);
}

const moreStats = [
  {
    id: 1,
    name: "New Policy Updates",
    stat: "14",
    icon: NewspaperIcon,
    change: "Reviewed: 1/21/2024",
    changeType: "increase",
  },
  {
    id: 2,
    name: "Rate Changes (< 6mo)",
    stat: "27",
    icon: ArrowUpCircleIcon,
    // change: "Last Review: 2/21/2024",
    // changeType: "increase",
  },
  {
    id: 3,
    name: "Denials (< 3mo)",
    stat: "240",
    icon: ShieldExclamationIcon,
    change: `Sync: ${getFormattedTimeFromNow(1)}`,
    changeType: "sync",
  },
  {
    id: 4,
    name: "Upcoming Contracts Renewal (~1 yr)",
    stat: "9",
    icon: ArrowUpCircleIcon,
    // change: "Last Review: 2/21/2024",
    // changeType: "increase",
  },
  {
    id: 5,
    name: "Payment Variances (< 1yr)",
    stat: "$740,300",
    icon: BanknotesIcon,
    change: `Sync: ${getFormattedTimeFromNow(0)}`,
    changeType: "sync",
  },
  {
    id: 6,
    name: "Process Recommendations",
    subText: "Coming Soon",
    icon: CursorArrowRaysIcon,
  },
];

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

const days = [
  {
    date: "Today",
    dateTime: "2023-03-22",
    transactions: [
      {
        id: 1,
        invoiceNumber: "00012",
        href: "#",
        amount: "$7,600.00 USD",
        // tax: "$500.00",
        status: "Paid",
        client: "Aetna",
        description: "Bank of America *1101 Account",
        icon: ArrowUpCircleIcon,
      },
      {
        id: 2,
        invoiceNumber: "00011",
        href: "#",
        amount: "$10,000.00 USD",
        status: "Paid",
        client: "Blue Cross of Maryland",
        description: "Bank of America *1101 Account",
        icon: ArrowDownCircleIcon,
      },
      {
        id: 3,
        invoiceNumber: "00009",
        href: "#",
        amount: "$2,000.00 USD",
        // tax: "$130.00",
        status: "Overdue",
        client: "Blue Cross of Maryland",
        description: "Claim denied - Check provider portal",
        icon: ArrowPathIcon,
      },
    ],
  },
  {
    date: "Yesterday",
    dateTime: "2023-03-21",
    transactions: [
      {
        id: 4,
        invoiceNumber: "00009",
        href: "#",
        amount: "$1,050.00 USD",
        // tax: "$130.00",
        status: "Overdue",
        client: "Blue Cross of Maryland",
        description: "Claim denied - Check provider portal",
        icon: ArrowPathIcon,
      },
    ],
  },
];

// TODO: change to services
const clients = [
  {
    id: 1,
    name: "Aetna",
    imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
    lastInvoice: {
      date: "July 13, 2024",
      dateTime: "2022-12-13",
      amount: "$110,263.11",
      status: "Paid",
    },
  },
  {
    id: 2,
    name: "Anthem",
    imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
    lastInvoice: {
      date: "July 1, 2024",
      dateTime: "2023-01-22",
      amount: "$58,129.23",
      status: "Paid",
    },
  },
  {
    id: 3,
    name: "Blue Cross",
    imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
    lastInvoice: {
      date: "July 1, 2024",
      dateTime: "2023-01-23",
      amount: "$76,608.94",
      status: "Paid",
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const generateData = () =>
  [...Array(12)].map((_, i) => ({
    month: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][i],
    revenue: Math.floor(Math.random() * 5000) + 1000,
  }));

const formatCurrency = (value) => {
  if (typeof value !== "number" || isNaN(value)) {
    return "$0.00";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const AnimatedNumber_ = ({ value, precision = 2 }) => {
  const [displayValue, setDisplayValue] = useState(0);
  const previousValue = useRef(0);

  useEffect(() => {
    const startValue = previousValue.current;
    const endValue = Number(value) || 0;

    let startTime;
    const animationDuration = 1000; // 1 second

    const animateValue = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / animationDuration, 1);

      if (progress < 1) {
        const currentValue = startValue + (endValue - startValue) * progress;
        setDisplayValue(parseFloat(currentValue.toFixed(precision)));
        requestAnimationFrame(animateValue);
      } else {
        setDisplayValue(endValue);
      }
    };

    requestAnimationFrame(animateValue);
    previousValue.current = endValue;
  }, [value, precision]);

  return formatCurrency(displayValue);
};

const AnimatedNumber = ({ value, precision = 2 }) => {
  value = Number(value) || 0;

  const [displayValue, setDisplayValue] = useState(value);
  const [flash, setFlash] = useState(null);
  const previousValue = useRef(value);

  useEffect(() => {
    let startTime;
    const animationDuration = 1000; // 1 second

    const animateValue = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / animationDuration;

      if (progress < 1) {
        const currentValue =
          previousValue.current + (value - previousValue.current) * progress;
        setDisplayValue(parseFloat(currentValue.toFixed(precision)));
        requestAnimationFrame(animateValue);
      } else {
        setDisplayValue(value);
      }
    };

    requestAnimationFrame(animateValue);

    // Set flash color based on value change
    if (value > previousValue.current) {
      setFlash("green");
    } else if (value < previousValue.current) {
      setFlash("red");
    }

    // Reset flash after a short delay
    const flashTimeout = setTimeout(() => setFlash(null), 1000);

    previousValue.current = value;

    return () => clearTimeout(flashTimeout);
  }, [value, precision]);

  return (
    <span
      className={`transition-colors duration-300 ${
        flash === "green"
          ? "text-green-500"
          : flash === "red"
          ? "text-red-500"
          : ""
      }`}
    >
      {formatCurrency(displayValue)}
    </span>
  );
};

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [stats, setStats] = useState(financialStats);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Make the financial overview look more "active" took features from crypto exchanges
  useEffect(() => {
    const updateInterval = setInterval(() => {
      setStats((prevStats) => {
        const newStats = [...prevStats];
        const postedPayments = newStats.find(
          (stat) => stat.name === "Posted Payments"
        );
        const expectedAR = newStats.find((stat) => stat.name === "Expected AR");
        const totalBilledCharges = newStats.find(
          (stat) => stat.name === "Total Billed Charges"
        );

        // Randomly decide whether to increase "Posted Payments" or "Total Billed Charges"
        const increasePostedPayments = Math.random() < 0.5;

        if (increasePostedPayments && postedPayments && expectedAR) {
          // Increase "Posted Payments" and decrease "Expected AR" by the same value (not percentage)
          const adjustmentValue = Math.random() * 500; // Random value to adjust
          postedPayments.value = (
            parseFloat(postedPayments.value) + adjustmentValue
          ).toFixed(2);
          expectedAR.value = (
            parseFloat(expectedAR.value) - adjustmentValue
          ).toFixed(2);
          postedPayments.change = `+${adjustmentValue.toFixed(2)}`;
          expectedAR.change = `-${adjustmentValue.toFixed(2)}`;
          postedPayments.changeType = "positive";
          expectedAR.changeType = "negative";
        } else if (totalBilledCharges && expectedAR) {
          // Increase "Total Billed Charges" and "Expected AR" by the same value (not percentage)
          const adjustmentValue = Math.random() * 500; // Random value to adjust
          totalBilledCharges.value = (
            parseFloat(totalBilledCharges.value) + adjustmentValue
          ).toFixed(2);
          expectedAR.value = (
            parseFloat(expectedAR.value) + adjustmentValue
          ).toFixed(2);
          totalBilledCharges.change = `+${adjustmentValue.toFixed(2)}`;
          expectedAR.change = `+${adjustmentValue.toFixed(2)}`;
          totalBilledCharges.changeType = "positive";
          expectedAR.changeType = "positive";
        }

        return newStats;
      });
    }, 5000); // Update every 5 seconds

    return () => clearInterval(updateInterval);
  }, []);

  return (
    <>
      <main>
        {/* More Stats (Contracts) */}
        <div className="p-10 bg-gray-100">
          <div className="flex items-center space-x-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Contracts Overview
            </h3>
            <div className="flex items-center space-x-2">
              <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></div>
              <span className="text-sm font-medium text-green-600">Live</span>
            </div>
            <span className="text-sm text-gray-500">
              Current Time: {currentTime.toLocaleTimeString()}
            </span>
          </div>

          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {moreStats.map((item) => (
              <div
                key={item.id}
                className={classNames(
                  item.name === "Financial Overview"
                    ? "text-gray-500 lg:col-span-3 sm:col-span-2 col-span-1 bg-white"
                    : "text-gray-500 bg-white",
                  "relative overflow-hidden rounded-lg justify-center flex flex-col   px-4 py-5 min-h-[130px] max-h-[130px]  shadow sm:px-6 sm:pt-6"
                )}
              >
                <div className="flex flex-row ">
                  <div className="absolute rounded-md bg-[#0D9488] p-3">
                    <item.icon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  <div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                    {item.subText && (
                      <p className="ml-16 text-2xl font-semibold text-gray-500">
                        {item.subText}
                      </p>
                    )}
                  </div>
                </div>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </p>
                  <p
                    className={classNames(
                      item.changeType === "increase"
                        ? "text-green-600"
                        : "text-green-600 animate-pulse",
                      "ml-2 flex items-baseline text-sm font-semibold"
                    )}
                  >
                    {item.changeType === "increase" ? (
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500 mr-1"
                      />
                    ) : item.changeType === "sync" ? (
                      <ArrowPathIcon
                        aria-hidden="true"
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500 mr-1"
                      />
                    ) : item.changeType === "decrease" ? (
                      <ArrowDownIcon
                        aria-hidden="true"
                        className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                      />
                    ) : null}

                    <span className="sr-only">
                      {item.changeType === "increase"
                        ? "Increased"
                        : "Decreased"}{" "}
                      by
                    </span>
                    {item.change}
                  </p>
                  {/* <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        View all
                        <span className="sr-only"> {item.name} stats</span>
                      </a>
                    </div>
                  </div> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="relative isolate overflow-hidden">
          {/* Secondary navigation */}
          <header className="pt-10 pb-4 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-7 text-gray-900">
                Financial Overview
              </h1>
              <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                {secondaryNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={
                      item.current ? "text-indigo-600" : "text-gray-700"
                    }
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {/* <a
                href="#"
                className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusSmallIcon aria-hidden="true" className="-ml-1.5 h-5 w-5" />
                New invoice
              </a> */}
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {stats.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1
                      ? "sm:border-l"
                      : statIdx === 2
                      ? "lg:border-l"
                      : "",
                    "flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">
                    {stat.name}
                  </dt>
                  <dd
                    className={classNames(
                      stat.changeType === "negative"
                        ? "text-rose-600"
                        : "text-green-700",
                      "text-xs font-medium"
                    )}
                  >
                    {stat.change}
                  </dd>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {/* {stat.value} */}
                    <AnimatedNumber value={stat.value} />
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div
            aria-hidden="true"
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          >
            <div
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            />
          </div>
        </div>

        <div className="space-y-16 pt-16 pb-6 xl:space-y-20 w-full">
          <SimpleBarChart />
        </div>
        <div className="space-y-16 pt-6 pb-16 xl:space-y-20">
          {/* Recent activity table */}
          <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
                Recent activity
              </h2>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Amount (30 days)</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {days.map((day) => (
                        <Fragment key={day.dateTime}>
                          <tr className="text-sm leading-6 text-gray-900">
                            <th
                              scope="colgroup"
                              colSpan={3}
                              className="relative isolate py-2 font-semibold"
                            >
                              <time dateTime={day.dateTime}>{day.date}</time>
                              <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                              <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                            </th>
                          </tr>
                          {day.transactions.map((transaction) => (
                            <tr key={transaction.id}>
                              <td className="relative py-5 pr-6">
                                <div className="flex gap-x-6">
                                  <transaction.icon
                                    aria-hidden="true"
                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                  />
                                  <div className="flex-auto">
                                    <div className="flex items-start gap-x-3">
                                      <div className="text-sm font-medium leading-6 text-gray-900">
                                        {transaction.amount}
                                      </div>
                                      <div
                                        className={classNames(
                                          statuses[transaction.status],
                                          "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                        )}
                                      >
                                        {transaction.status}
                                      </div>
                                    </div>
                                    {transaction.tax ? (
                                      <div className="mt-1 text-xs leading-5 text-gray-500">
                                        {transaction.tax} tax
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm leading-6 text-gray-900">
                                  {transaction.client}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {transaction.description}
                                </div>
                              </td>
                              <td className="py-5 text-right">
                                <div className="flex justify-end">
                                  <a
                                    href={transaction.href}
                                    className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                                  >
                                    View
                                    <span className="hidden sm:inline">
                                      {" "}
                                      transaction
                                    </span>
                                    <span className="sr-only">
                                      , invoice #{transaction.invoiceNumber},{" "}
                                      {transaction.client}
                                    </span>
                                  </a>
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  Invoice{" "}
                                  <span className="text-gray-900">
                                    #{transaction.invoiceNumber}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Revenues Breakdown by Insurance (Last 90 days)
                </h2>
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  View all<span className="sr-only">, expand</span>
                </a>
              </div>
              <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {clients.map((client) => (
                  <li
                    key={client.id}
                    className="overflow-hidden rounded-xl border border-gray-200"
                  >
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <img
                        alt={client.name}
                        src={client.imageUrl}
                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        {client.name}
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon
                            aria-hidden="true"
                            className="h-5 w-5"
                          />
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            >
                              View
                              <span className="sr-only">, {client.name}</span>
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            >
                              Edit
                              <span className="sr-only">, {client.name}</span>
                            </a>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Latest transaction</dt>
                        <dd className="text-gray-700">
                          <time dateTime={client.lastInvoice.dateTime}>
                            {client.lastInvoice.date}
                          </time>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">30 day Cumulative</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">
                            {client.lastInvoice.amount}
                          </div>
                          <div
                            className={classNames(
                              statuses[client.lastInvoice.status],
                              "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                            )}
                          >
                            {client.lastInvoice.status}
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
