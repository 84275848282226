import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { documentTypes } from "../../lib/constants";
import { CoPilotContext } from "../../context/co-pilot-context";
import { useDenialCode } from "../../hooks/useDenialCode";
import { useProcedure } from "../../hooks/useProcedure";
export const ReconActionButtons = (props) => {
  const { item, title } = props;
  const { denialCodeData } = useDenialCode(item?.denialCode);
  const { procedure } = useProcedure(item?.procedure);
  const { setUseParams, setUserQuery, updateLocalStorage } =
    useContext(CoPilotContext);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {title}
          <ChevronDownIcon
            aria-hidden="true"
            className="-mr-1 h-5 w-5 text-gray-400"
          />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0  z-10 mt-2 w-auto overflow-x-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1 px-2 ">
          <MenuItem>
            <a
              onClick={() => {
                updateLocalStorage("useParams", true);
                updateLocalStorage(
                  "userQuery",
                  `Explain the ${item.payer} Policy for ${denialCodeData?.bucket} (Denial Code ${item.denialCode}) on CPT Procedure '${procedure?.label}' (${item.procedure})`
                );
                window.open(
                  `${window.location.origin}/chat?document_type=${documentTypes[1]}`,
                  "_blank"
                );
              }}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              {`Explain the ${item.payer} Policy for this Denial`}
            </a>
          </MenuItem>
          <MenuItem>
            <a
              onClick={() => {
                window.open(
                  `${
                    window.location.origin
                  }/ai-phone-agent?openNewTaskModal=true&showNewTask=true&phoneNumber=123-456-7890&template=${`Claim Appeal Status Check`}&notes=${`Please inquire about the status of our appeal for denied claim ${item.claim}, with denial code ${item.denialCode}`}`,
                  "_blank"
                );
              }}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Generate a Call Task
            </a>
          </MenuItem>
          <MenuItem>
            <a
              onClick={() => {
                updateLocalStorage("useParams", true);
                updateLocalStorage(
                  "userQuery",
                  `Explain the ${item.payer} Policy for ${denialCodeData?.bucket} (Denial Code ${item.denialCode}) on CPT Procedure '${procedure?.label}' (${item.procedure}). Then generate an letter for appealing this denied claim.`
                );

                window.open(`${window.location.origin}/chat`, "_blank");
              }}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Generate Appeal Letter
            </a>
          </MenuItem>
          {/* <MenuItem>
              <a
                onClick={() => {
                  updateLocalStorage("useParams", true);
                  updateLocalStorage(
                    "userQuery",
                    `Find Relevant Sections in ${item.payer}’s Policies for Denial Code`
                  );
                  window.open(
                    `${window.location.origin}/chat`,
                    "_blank"
                  );
                }}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
              >
                {`Find Relevant Sections in ${item.payer}’s Policies for Denial Code`}
              </a>
            </MenuItem> */}
        </div>
      </MenuItems>
    </Menu>
  );
};
