import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { handleRedirection } from "../lib/helper-functions";
export const withAuthorization = (Component) => (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user?.uid;
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        console.log("user data", userData);
        await handleRedirection(uid, navigate);
        setIsLoading(false);
      } else {
        // User is signed out
        navigate("/");
        // ...
        setIsLoading(false);
        console.log("user is logged out");
      }
    });
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="lg:h-[100vh]  bg-[white] lg:overflow-y-scroll lg:no-scrollbar no-scrollbar"></div>
    );
  }

  return <Component {...props} />;
};
