import React, { useState, useContext, useEffect } from "react";
import { Search, Calendar, Filter, ChevronUp, ChevronDown } from "lucide-react";
import { Dialog, Transition } from "@headlessui/react";
import { ModalContext } from "../../context/modal-context";
import { truncateText } from "../../lib/helper-functions";

const InsightEngine = () => {
  const {
    filters,
    setFilters,
    results,
    setResults,
    isFilterModalOpen,
    setIsFilterModalOpen,
    handleFilterChange,
    searchCptCode: originalSearchCptCode,
    setContractModalPdfDetails,
    setOpenPdfViewer,
  } = useContext(ModalContext);

  const columnDisplayNames = {
    payer_name: "Payer",
    cpt_code: "CPT Code",
    modifier: "CPT Modifier",
    effective_date_start: "Effective Date",
    effective_date_end: "Termination Date",
    plan_name: "Plan",
    fee_schedule_name: "Fee Schedule",
    adjusted_fee: "Contracted Rate",
    reimbursement_amount: "Schedule Rate",
    percentage: "Schedule Percentage",
    clause_id: "Clause"
    // ... add other custom names as needed
  };

  const excludedFields = [
    "cfs_id",
    "provider_type_id",
    "fee_schedule_id",
    "fsa_id",
    "created_at",
    "updated_at",
    "clause_description",
    "payer_description",
    "page_number",
    "bbox_coordinates",
    "s3_file_path",
    "plan_type"
  ];

  const predefinedColumnOrder = [
    "payer_name",
    "plan_name",
    "cpt_code",
    "modifier",
    "clause_id",
    "carrier_code",
    "locality_code",
    "effective_date_start",
    "effective_date_end",
    "fee_schedule_name",
    "reimbursement_amount",
    "percentage",
    "adjusted_fee"
    // Add other predefined columns here in the order you want them to appear
  ];

  const [sortColumn, setSortColumn] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [searchError, setSearchError] = useState("");

  useEffect(() => {
    if (isLoading) {
      const statusMessages = [
        "Compiling CPTs matching search",
        "Aggregating contract clauses that affect this CPT",
        "Conducting contracts analysis...",
      ];

      let currentStatusIndex = 0;

      const statusInterval = setInterval(() => {
        setLoadingStatus(statusMessages[currentStatusIndex]);
        currentStatusIndex++;

        // Loop back to the last message instead of clearing the interval
        if (currentStatusIndex >= statusMessages.length) {
          currentStatusIndex = statusMessages.length - 1; // Stay on the last message
        }
      }, 750); // Change status

      // Clear the interval when the component is unmounted or isLoading changes to false
      return () => clearInterval(statusInterval);
    }
  }, [isLoading]);

  const searchCptCode = () => {
    const errorMessage = validateCptCode(filters.searchTerm);
    if (errorMessage) {
      setSearchError(errorMessage);
      return; // Stop the search if the CPT code is invalid
    }

    setSearchError("");

    setIsLoading(true);
    const startTime = Date.now();

    originalSearchCptCode().finally(() => {
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;

      if (timeElapsed < 3000) {
        // If the search was too fast, wait until a few seconds has passed
        setTimeout(() => {
          setIsLoading(false);
        }, 3000 - timeElapsed);
      } else {
        // If a second or more has passed, stop loading immediately
        setIsLoading(false);
      }
    });
  };

  const validateCptCode = (code) => {
    // Check if the code is empty
    if (!code.trim()) {
      return "CPT code cannot be empty.";
    }
  
    // Check if the code has the correct length
    if (code.length !== 5) {
      return "CPT code must be 5 characters long.";
    }
  
    // Check if the code matches the alphanumeric format (HCPCS Level II)
    const hcpcsRegex = /^[A-Za-z][0-9]{4}$/;
    if (hcpcsRegex.test(code)) {
      return ""; // No error, HCPCS Level II code is valid
    }
  
    // Check if the code matches the numeric format (CPT)
    const cptRegex = /^[0-9]{5}$/;
    if (cptRegex.test(code)) {
      return ""; // No error, CPT code is valid
    }
  
    // If none of the above checks pass, return a generic error message
    return "Invalid CPT code format. Please enter a valid code.";
  };

  // low priority item
  // const clearSearchResults = () => {
  //   setFilters({ ...filters, searchTerm: '' }); // Reset the search term
  //   setSearchError(''); // Clear any search errors
  //   setResults([]); // Clear the results
  //   // Add any other state resets if needed
  // };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchCptCode();
    }
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }

    const sortedResults = [...results].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    setResults(sortedResults);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/(\d+)(?:st|nd|rd|th)/, (_, day) => {
        const suffix =
          ["th", "st", "nd", "rd"][day > 3 && day < 21 ? 0 : day % 10] || "th";
        return `${day}${suffix}`;
      });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const formatCellValue = (key, value) => {
    if (key === "effective_date_start" || key === "effective_date_end") {
      return value ? formatDate(value) : "-";
    }
    if (key === "reimbursement_amount" || key === "adjusted_fee") {
      return formatCurrency(value);
    }
    if (key === "percentage" || key.toLowerCase().includes("percent")) {
      return value !== null && value !== undefined
        ? `${(value * 100).toFixed(1)}%`
        : "-";
    }
    if (key === "condition_details") {
      return truncateText(value);
    }
    return value;
  };

  const handleClauseIdClick = (item) => {
    setContractModalPdfDetails([
      {
        filePath: item.s3_file_path,
        modalHighlights: [
          {
            page: item.page_number,
            bbox: item.bbox_coordinates,
          },
        ],
      },
    ]);
    setOpenPdfViewer(true);
  };

  const generateTableColumns = () => {
    if (results.length === 0) return [];
  
    // Create an object to keep track of columns with values
    const columnHasValue = {};
  
    // Initialize all columns to false (no values)
    Object.keys(results[0])
      .filter((key) => !excludedFields.includes(key))
      .forEach((key) => {
        columnHasValue[key] = false;
      });
  
    // Check each result to see if any column has a value
    results.forEach((result) => {
      Object.keys(result).forEach((key) => {
        if (
          result[key] !== null &&
          result[key] !== undefined &&
          !excludedFields.includes(key)
        ) {
          columnHasValue[key] = true;
        }
      });
    });
  
    // Create columns for predefined columns that have values
    const predefinedColumnsWithValues = predefinedColumnOrder
      .filter((key) => columnHasValue[key])
      // .map((key) => ({
      //   key: key,
      //   label: key
      //     .split("_")
      //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      //     .join(" "),
      // }));
      .map((key) => ({
        key: key,
        label: columnDisplayNames[key] || key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      }));
  
    // Create columns for other columns that have values and are not predefined
    const otherColumnsWithValues = Object.keys(columnHasValue)
      .filter((key) => columnHasValue[key] && !predefinedColumnOrder.includes(key))
      .map((key) => ({
        key: key,
        label: key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      }));
  
    // Combine predefined columns with other columns, maintaining the order
    return [...predefinedColumnsWithValues, ...otherColumnsWithValues];
  };
  
  const tableColumns = generateTableColumns();

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6 lg:p-8">
      <h1 className="text-2xl font-semibold mb-2 px-2 bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
        InsightEngine
      </h1>
      <h1 className="text-lg mb-6 px-2">
        Enter a CPT code to see Prices and Contract Clauses
      </h1>

      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-2">
            <label
              htmlFor="searchTerm"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              CPT Code
            </label>
            <div className="relative">
              <input
                type="text"
                name="searchTerm"
                id="searchTerm"
                value={filters.searchTerm}
                onChange={handleFilterChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Search..."
                onPress={handleKeyPress}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            {searchError && (
              <p className="mt-2 text-sm text-red-600" id="search-error">
                {searchError}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Start Date
            </label>
            <div className="relative">
              <input
                type="date"
                // disabled={true}
                name="startDate"
                id="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="block w-full pl-10 pr-3 py-2 border  disabled:text-gray-300 disabled:bg-gray-100 border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400 " />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              End Date
            </label>
            <div className="relative">
              <input
                type="date"
                name="endDate"
                // disabled={true}
                id="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="block w-full disabled:text-gray-300 disabled:bg-gray-100 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <button
            // disabled={true}
            type="button"
            onClick={() => setIsFilterModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Filter className="h-5 w-5 mr-2" />
            More Filters
          </button>
          <div>
          <button
          type="button"
          onClick={searchCptCode}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Search
        </button>
        {/* {filters.searchTerm && (
          <button
            type="button"
            onClick={clearSearchResults}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-2"
          >
            Clear Search
          </button>
        )} */}
        
      </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex flex-col justify-center items-center">
          <div className="loader"></div>
          <div className="mt-4 text-center">
            <p className="text-sm font-medium text-gray-700">{loadingStatus}</p>
          </div>
        </div>
      ) : results.length > 0 ? (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {tableColumns.map((column) => (
                    <th
                      key={column.key}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort(column.key)}
                    >
                      <div className="flex items-center">
                        {column.label}
                        {sortColumn === column.key &&
                          (sortDirection === "asc" ? (
                            <ChevronUp className="w-4 h-4 ml-1" />
                          ) : (
                            <ChevronDown className="w-4 h-4 ml-1" />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {results.map((result, index) => (
                  <tr key={index}>
                    {tableColumns.map((column) => (
                      <td
                        key={`${index}-${column.key}`}
                        className={`px-6 py-4 whitespace-nowrap text-sm ${
                          column.key === "clause_id"
                            ? "text-blue-600 hover:text-blue-800 underline cursor-pointer"
                            : "text-gray-500"
                        }`}
                        onClick={() =>
                          column.key === "clause_id"
                            ? handleClauseIdClick(result)
                            : null
                        }
                      >
                        {formatCellValue(column.key, result[column.key])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-gray-200 shadow sm:rounded-lg p-12 text-center h-[450px] justify-center items-center flex">
          <p className="text-gray-500 text-sm">
            To see more results, search with a new procedure code or reduce the filters.
          </p>
        </div>
      )}
    </div>
  );
};

export default InsightEngine;
