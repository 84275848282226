import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const MultiselectFilter = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  onSelectOpen,
  formatLabel,
}) => {
  const [selected, setSelected] = useState(value || []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelected(value || []);
  }, [value]);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );

  const toggleAll = () => {
    const newSelected = selected.length === options.length ? [] : options;
    setSelected(newSelected);
    onChange(newSelected);
  };
  const removeOption = (optionToRemove) => {
    const newSelected = selected.filter((item) => item !== optionToRemove);
    setSelected(newSelected);
    onChange(newSelected);
  };

  const truncateLabel = (text, maxLength = 15) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const handleChange = (newSelected) => {
    setSelected(newSelected);
    onChange(newSelected);
  };

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={handleChange}
      multiple
      onOpen={() => onSelectOpen(true)}
      onClose={() => onSelectOpen(false)}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        <div className="flex flex-wrap gap-1 p-1 border rounded-md">
          {selected.length === options.length ? (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
              All
            </span>
          ) : (
            selected.map((item) => (
              <span
                key={item.value}
                className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
              >
                <span className="truncate max-w-[100px]" title={item.label}>
                  {truncateLabel(formatLabel ? formatLabel(item) : item.label)}
                </span>
                <button
                  type="button"
                  onClick={() => removeOption(item)}
                  className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                >
                  <span className="sr-only">Remove {item.label}</span>
                  <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </span>
            ))
          )}
          <Combobox.Input
            className="flex-1 border-0 bg-transparent p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={selected.length === 0 ? placeholder : ""}
          />
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
            <button
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-500"
              onClick={toggleAll}
            >
              {selected.length === options.length
                ? "Deselect All"
                : "Select All"}
            </button>
          </div>
          {filteredOptions.map((option) => (
            <Combobox.Option
              key={option.value}
              value={option}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-indigo-600 text-white" : "text-gray-900"
                }`
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {formatLabel ? formatLabel(option) : option.label}
                  </span>
                  {selected && (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? "text-white" : "text-indigo-600"
                      }`}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export const FilterModal = (props) => {
  const {
    filters,
    setFilters,
    isFilterModalOpen,
    setIsFilterModalOpen,
    searchCptCode,
    filterOptions,
  } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterChange = (name, value) => {
    console.log("NAME ---", name);
    console.log("VALUE", value);
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    const clearedFilters = {
      providerType: [],
      carrier: [],
      locality: [],
      payer: [],
      plan: [],
    };
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...clearedFilters,
    }));
    console.log("Filters cleared:", clearedFilters);
  };

  const providerTypeOptions = filterOptions?.providerTypeOptions || [];
  const carrierOptions = filterOptions?.carrierCodeOptions || [];
  const localityOptions = filterOptions?.localityCodeOptions || [];
  const payerOptions = filterOptions?.payerOptions || [];
  const planOptions = filterOptions?.planOptions || [];
  const clauseOptions = filterOptions?.clauseOptions || [];
  const feeScheduleOptions = filterOptions?.feeScheduleOptions || [];

  const formatLocalityLabel = (option) => `${option.value} - ${option.label}`;

  return (
    <Transition appear show={isFilterModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsFilterModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${
                  isSelectOpen ? "h-auto max-h-[80vh]" : ""
                }`}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Additional Filters
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                  onClick={() => setIsFilterModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="mt-4 space-y-4">
                  {[
                    {
                      name: "providerType",
                      label: "Provider Type",
                      options: providerTypeOptions,
                    },
                    {
                      name: "carrier",
                      label: "Carrier Code",
                      options: carrierOptions,
                    },
                    {
                      name: "locality",
                      label: "Locality Code",
                      options: localityOptions,
                      formatLabel: formatLocalityLabel,
                    },
                    {
                      name: "payer",
                      label: "Payer",
                      options: payerOptions,
                    },
                    {
                      name: "plan",
                      label: "Plan",
                      options: planOptions,
                    },
                    {
                      name: "clause",
                      label: "Clause Id",
                      options: clauseOptions,
                    },
                    {
                      name: "feeSchedule",
                      label: "Fee Schedule",
                      options: feeScheduleOptions,
                    },
                  ].map((field) => (
                    <div key={field.name} className="space-y-2">
                      <MultiselectFilter
                        options={field.options}
                        value={filters[field.name]}
                        onChange={(selected) =>
                          handleFilterChange(field.name, selected)
                        }
                        label={field.label}
                        placeholder={`Select ${field.label.toLowerCase()}`}
                        onSelectOpen={(isOpen) => setIsSelectOpen(isOpen)}
                        formatLabel={field.formatLabel}
                      />
                    </div>
                  ))}
                </div>
                <div className="mt-8 flex justify-between items-center">
                  <button
                    type="button"
                    className="text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={clearFilters}
                  >
                    Clear all
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={async () => {
                      setIsLoading(true);
                      try {
                        await searchCptCode();
                      } finally {
                        setIsLoading(false);
                        setIsFilterModalOpen(false);
                      }
                    }}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Applying...
                      </>
                    ) : (
                      "Apply Filter"
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
