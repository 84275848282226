import React, { useEffect, useState, useContext } from "react";
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { ModalContext } from "../../context/modal-context";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PatientPaymentPage = () => {
  const patientPaymentTableHeader = [
    "Patient Name",
    "Patient Phone",
    "Payment Amt.",
    "Insurance Amt.",
    "CPT Code",
    // "Procedure Details",
    "Payment Links",
    "Status",
  ];
  // Columns:
  // - Patient Name
  // - Patient Phone Number
  // -
  // -
  // -
  // -
  // - Status (Empty aka no tag, Sent Text, Sent Email, Paid)

  const templateTableHeader = ["Template Name", "Template Fields"];

  const {
    openNewTaskModal,
    setOpenNewTaskModal,
    handleAddNewTaskToDb,
    openNewTemplateModal,
    setOpenNewTemplateModal,
    openDetailsModal,
    setOpenDetailsModal,
    setDetails,
  } = useContext(ModalContext);

  const [phoneAgentDataState, setPhoneAgentDataState] = useState([]);
  const [templateDataState, setTemplateDataState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "Phone Tasks", href: "#", icon: UserIcon, current: true },
    { name: "Template", href: "#", icon: BuildingOfficeIcon, current: false },
  ]);

  const setCurrentTab = (tabName) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === tabName,
      }))
    );
  };
  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  const getPhoneAgents = async (isNextPage = false) => {
    let q;
    if (isNextPage && lastVisible) {
      q = query(
        collection(db, "patient payments"),
        orderBy("patientPhoneNumber"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      q = query(
        collection(db, "patient payments"),
        orderBy("patientPhoneNumber"),
        limit(itemsPerPage)
      );
    }

    const snapshot = await getDocs(q);
    const phoneAgentData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (phoneAgentData.length < itemsPerPage) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    }

    setPhoneAgentDataState(phoneAgentData);
  };

  const getTemplates = async () => {
    let q = query(
      collection(db, "template"),
      orderBy("createdAt"),
      limit(itemsPerPage)
    );

    const snapshot = await getDocs(q);
    const templateData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setTemplateDataState(templateData);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Other info
  // - Payment link should have values that say "Open Payment Link" and that should open up to the slope demo you are making
  // - Your slope demo should accept a values in the query parameters that allow changing the payment amount, details of service/product and name of the patient
  // - Your slope demo should have zero mentions of slope, everything should say recon
  // - Your slope demo should have the modal open by default so when user lands on the page the modal auto shows

  const handleNextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage(currentPage + 1);
      getPhoneAgents(true);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getPhoneAgents();
    }
  };

  useEffect(() => {
    getPhoneAgents();
    getTemplates();
  }, []);

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 overflow-hidden bg-gray-100">
      <div className="min-h-screen bg-gray-100 overflow-hidden">
        <div className="min-h-screen bg-gray-100">
          <div className=" py-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Patient Payments
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-3">
                <button
                  type="button"
                  // onClick={() => setOpenNewTaskModal(true)}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Upload accounts recievable
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          {patientPaymentTableHeader.map((item, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {phoneAgentDataState.map((item, index) => (
                          <tr
                            key={item.id}
                            className={
                              index % 2 === 0 ? "bg-white" : "bg-gray-50"
                            }
                          >
                            <td className=" whitespace-pre-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {item.patientName}
                            </td>
                            <td className=" px-3  whitespace-nowrap  truncate py-4 text-sm text-gray-500">
                              <p className="truncate">
                                {item.patientPhoneNumber}
                              </p>
                            </td>
                            <td className=" whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              <span>{item.paymentAmount}</span>
                            </td>
                            <td className="px-3 py-4  whitespace-pre-wrap text-sm text-gray-500 max-w-xs truncate text-center">
                              {item.insuranceReimbursementAmount}
                            </td>
                            <td className="px-3 py-4  whitespace-pre-wrap text-sm text-gray-500 max-w-xs truncate">
                              {item.cptCode} / {item.procedureDetails}
                            </td>
                            {/* <td className=" whitespace-pre-wrap  px-3 py-4 text-sm text-gray-500">
                              {item.procedureDetails}
                            </td> */}

                            <td className=" whitespace-nowrap underline px-3 py-4 cursor-pointer text-sm text-blue-500">
                              <a
                                target="_blank"
                                href={`https://recon-experiments.onrender.com/?procedure-name=${encodeURIComponent(
                                  item.procedureName
                                )}&procedure-price=${encodeURIComponent(
                                  item.paymentAmount
                                )}&patient-name=${encodeURIComponent(
                                  item.patientName
                                )}`}
                              >
                                {" "}
                                {item.paymentLinks}
                              </a>
                            </td>

                            {/*  */}
                            <td className="px-3 py-4 text-md text-gray-500">
                              <span
                                className={classNames(
                                  "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                  item.status === "Sent Email"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : item.status === "Paid"
                                    ? "bg-green-100 text-green-800"
                                    : item.status === "Sent Text"
                                    ? "bg-blue-100 text-blue-800"
                                    : "bg-gray-300 text-gray-800"
                                )}
                              >
                                {item.status == "Sent Email" ? "Emailed" : item.status}
                              </span>
                            </td>
                            {/* <td
                              className=" px-3 py-4 whitespace-nowrap text-sm text-gray-500"
                              onClick={() => {
                                setDetails({
                                  phoneNumber: item.phoneNumber,
                                  template: item.template,
                                  progress: item.progress,
                                  notes: item.notes,
                                  contactInfo: item.contactInfo,
                                  detailsId: item?.agentDetailsId
                                    ? item?.agentDetailsId
                                    : null,
                                });
                                setOpenDetailsModal(true);
                              }}
                            >
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Open Details
                              </a>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-b-lg">
              <div className="flex flex-1 justify-between sm:hidden">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={isNextDisabled}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing page{" "}
                    <span className="font-medium">{currentPage}</span>
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={isNextDisabled}
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientPaymentPage;
