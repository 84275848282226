/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, signInWithGooglePopup } from "../firebase-config";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";

import { handleRedirection } from "../lib/helper-functions";

// Assuming you're using react-router for navigation

const quotes = [
  // '"Experts often possess more data than judgment." - Colin L. Powell                                                                                    ',
  "“Data is not just about numbers; it’s about stories.” - Radi                                                                                                  ",
  '"AI will impact every industry on Earth, including manufacturing, agriculture, health care, and more." - Fei Fei Li                                      ',
  "“Big data is at the intersection of technology and humanity.” - Thomas Redman                                                                                 ",
  '"Recon OS is the financial hub for healthcare organizations" - Sunrise Hospital Group                                                                                        ',
  '"The only real mistake is the one from which we learn nothing." - Henry Ford                                                                                 ',
  "“Having the data is not the same as having the expertise to look through the data - if it were, everybody with a smartphone would be a doctor or a scientist.”",
  '"With AI, anyone can gain the expertise to any type of knowledge, as long as they are willing to ask the right questions"                                      ',
  '"Prompts are like spells, with the right one, you can create magic" - Not Dumbledore',
  // '"Innovation distinguishes between a leader and a follower." - Steve Jobs'
];

export default function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(0);
  const [isQuoteVisible, setIsQuoteVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsQuoteVisible(false);
      setTimeout(() => {
        setCurrentQuote((prevQuote) => (prevQuote + 1) % quotes.length);
        setIsQuoteVisible(true);
      }, 500); // Wait for fade out before changing quote
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userid = userCredential.user.uid;
      const userEmail = userCredential.user.email;

      let userData = {
        id: userid,
        email: userEmail,
        createdAt: new Date(),
      };

      // Store the user data in the users document
      await setDoc(doc(db, "users", userid), userData);

      const signInUser = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      await handleRedirection(signInUser?.user?.uid, navigate);
    } catch (error) {
      console.error("Error: ", error);
      setError(error.message); // Update the error state
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithGooglePopup();
      console.log("THE RESPONSE", response);

      const userId = response?.user?.uid;
      const userEmail = response?.user?.email;

      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        let userData = {
          id: userId,
          email: userEmail,
          createdAt: new Date(),
        };
        // Call the create-customer endpoint
        // Store the user data in the user document
        await setDoc(userDocRef, userData);
      }
      await handleRedirection(userId, navigate);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage); // Update the error state
      console.log(errorCode, errorMessage);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img
              alt="Your Company"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              className="mx-auto h-10 w-auto"
            /> */}
          <h1 className="text-5xl font-extrabold text-center p-4">
            <span
              className={`bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 duration-1000 ${
                isQuoteVisible ? "opacity-100" : "opacity-10"
              }`}
            >
              Recon OS
            </span>
          </h1>
          {/* <h2 className="mt-6 text-center text-l leading-7 tracking-tight text-gray-600 italic">
            "AI will impact every industry on Earth, including manufacturing, agriculture, health care, and more." - Fei Fei Li, PhD
          </h2> */}
          {/* <h2 className="mt-6 text-center text-l leading-7 tracking-tight text-gray-600 italic">
            “Experts often possess more data than judgment.” - Colin L. Powell
          </h2> */}
          <h2
            className={`mt-2 text-center text-l leading-7 tracking-tight text-gray-600 italic transition-opacity duration-1000 ${
              isQuoteVisible ? "opacity-80" : "opacity-10"
            }`}
          >
            {quotes[currentQuote]}
          </h2>
          <h2 className="mt-1 text-center text-l leading-9 tracking-tight text-gray-600 italic"></h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form
              action="#"
              method="POST"
              className="space-y-6"
              onSubmit={onSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address or username
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    required
                    // autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="text"
                    required
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="masked-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div> */}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-teal-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign Up
                </button>
                {error && (
                  <p className="mt-2 text-center text-sm text-red-500">
                    {error}
                  </p>
                )}
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  aria-hidden="true"
                  className="absolute  inset-0 flex items-center"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-4">
                <a
                  onClick={handleGoogleSignIn}
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">
                    Google
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
