import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

import { Worker } from "@react-pdf-viewer/core";

import DashboardLayout from "./layouts/DashboardLayout";
import OverviewPage from "./pages/dashboard/overview";
import BillingPage from "./pages/dashboard/billing";
import ChatPage from "./pages/dashboard/chat";
import DenialsPage from "./pages/dashboard/denials";
import FilingRequirementsPage from "./pages/dashboard/filing-reqs";
import PriorAuthPage from "./pages/dashboard/prior-auth";
import AiPhoneAgentPage from "./pages/dashboard/ai-phone-agent";
import SignInPage from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import ForgotPasswordPage from "./pages/forgot-password";
import PaymentVariances from "./pages/dashboard/payment-variance";
import PatientPaymentPage from "./pages/dashboard/patient-payments";
import ContractedRatesPage from "./pages/dashboard/contracted-rates";
import InsightEngine from "./pages/dashboard/insight-engine";
import AuditResult from "./pages/dashboard/audit-result";
import ReviewAuditsPage from "./pages/dashboard/review-audit";
import UseCasesPage from "./pages/dashboard/use-case";
import QuestionStudio from "./pages/dashboard/question-studio";
import { ModalContextProvider } from "./context/modal-context";
import { CoPilotContextProvider } from "./context/co-pilot-context";
import { UserProvider } from "./context/user-context";

// Create a wrapper component for the dashboard layout
const DashboardRoutes = () => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

const App = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <UserProvider>
        <ModalContextProvider>
          <CoPilotContextProvider>
            <Router>
              <Routes>
                <Route path="/" element={<SignInPage />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route element={<DashboardRoutes />}>
                  <Route path="overview" element={<OverviewPage />} />
                  <Route path="billing" element={<BillingPage />} />
                  <Route path="filing" element={<FilingRequirementsPage />} />
                  <Route path="prior-auth" element={<PriorAuthPage />} />
                  <Route path="denials" element={<DenialsPage />} />

                  <Route
                    path="contracted-rates"
                    element={<ContractedRatesPage />}
                  />
                  <Route
                    path="payment-variances"
                    element={<PaymentVariances />}
                  />
                  <Route path="chat" element={<ChatPage />} />
                  <Route path="ai-phone-agent" element={<AiPhoneAgentPage />} />
                  <Route
                    path="patient-payments"
                    element={<PatientPaymentPage />}
                  />
                  <Route path="insight-engine" element={<InsightEngine />} />

                  <Route path="audit-result" element={<AuditResult />} />
                  <Route path="/review-audit" element={<ReviewAuditsPage />} />
                  <Route
                    path="/review-audit/:providerName"
                    element={<ReviewAuditsPage />}
                  />
                  <Route path="use-case" element={<UseCasesPage />} />
                  <Route path="question-studio" element={<QuestionStudio />} />
                  {/* Add more nested routes for dashboard here */}
                </Route>
                {/* Add more routes here */}
              </Routes>
            </Router>
          </CoPilotContextProvider>
        </ModalContextProvider>
      </UserProvider>
    </Worker>
  );
};

export default App;
