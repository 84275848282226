import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const activities = [
  {
    email: "bill@sunrisehospital.com",
    action: "Created EHR note for procedure",
    date: "10/22/2023",
    tag: "Epic",
  },
  {
    email: "mary@sunrisehospital.com",
    action: "Submitted claim",
    date: "11/23/2023",
    tag: "Clearinghouse",
  },
  {
    email: "mary@sunrisehospital.com",
    action: "Claim denial was received",
    date: "1/25/2024",
    tag: "Clearinghouse",
  },
  {
    email: "steven@recon.health",
    action: "Compiled related policies with Recon AI",
    date: "3/21/2024",
    tag: "Recon",
  },
  {
    email: "mary@sunrisehospital.com",
    action: "Created package for appealing claims",
    date: "3/22/2024",
    tag: "Epic",
  },
  {
    email: "mary@sunrisehospital.com",
    action: 'Created "Appeal Letter" with Recon AI',
    date: "3/25/2024",
    tag: "Recon",
  },
  {
    email: "mary@sunrisehospital.com",
    action: 'Created "Appeal Letter" with Recon AI',
    date: "3/27/24",
    tag: "Recon",
  },
];

const tagColors = {
  Epic: "bg-red-100 text-red-800",
  Clearinghouse: "bg-amber-100 text-amber-800",
  Recon: "bg-teal-100 text-teal-800",
};

const ActivityItem = ({ activity }) => (
  <li className="py-3 flex items-center space-x-4 group hover:bg-gray-50 rounded-lg transition-colors duration-150 ease-in-out">
    <div
      className={`w-2 h-2 rounded-full ${tagColors[activity.tag]
        .split(" ")[0]
        .replace("100", "500")}`}
    ></div>
    <div className="flex-1 min-w-0">
      <p className="text-sm font-medium text-gray-900 truncate">
        {activity.email}
      </p>
      <p className="text-sm text-gray-500 truncate">{activity.action}</p>
    </div>
    <div className="flex-shrink-0 flex items-center space-x-2">
      <span
        className={`px-2 py-1 text-xs font-medium rounded-full ${
          tagColors[activity.tag]
        }`}
      >
        {activity.tag}
      </span>
      <span className="text-xs text-gray-400">{activity.date}</span>
    </div>
  </li>
);

const ActivityTimeline = () => {
  const [expanded, setExpanded] = useState(false);
  const visibleActivities = expanded ? activities : activities.slice(0, 3);

  return (
    <div className="max-w-3xl mx-auto bg-white  rounded-lg overflow-hidden">
      <div className=" py-5 sm:py-6">
        <h4 className="text-base font-semibold text-gray-900 mb-2">
          Recent Activities
        </h4>
        <ul className="divide-y divide-gray-200">
          {visibleActivities.map((activity, index) => (
            <ActivityItem key={index} activity={activity} />
          ))}
        </ul>
        {activities.length > 3 && (
          <div className="px-4 py-5 sm:p-6">
            <button
              className="mt-4 w-full flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150 ease-in-out"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <>
                  <ChevronUp className="w-4 h-4 mr-2" />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown className="w-4 h-4 mr-2" />
                  Show More
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityTimeline;
