"use client";

import { useState, useEffect } from "react";
import axios from "axios";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { getAuth } from "firebase/auth";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Trigger, highlightPlugin } from "@react-pdf-viewer/highlight";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";

import { RECON_OS_SERVER_URI } from "@/lib/services";

export const PdfViewer = (props) => {
  const auth = getAuth();
  const { open, setOpen, contractModalPdfDetails, setContractModalPdfDetails } =
    props;
  console.log("contractModalPdfDetails", contractModalPdfDetails);
  const [pdfUrl, setPdfUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [initialPage, setInitialPage] = useState(0);

  const handleContractUrl = async (token, filePath) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${RECON_OS_SERVER_URI}/contract-file`, {
        headers: {
          Authorization: token,
        },
        params: {
          filePath: filePath,
        },
      });
      setPdfUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertToHighlightAreas = (items) => {
    const pageWidth = 610;
    const pageHeight = 790;
    if (items?.length > 0) {
      return items
        .map((item) => {
          const bbox = item?.bbox;
          const page = item?.page;
          if (bbox?.length > 0) {
            const [x, y, width, height] = bbox;
            const highlightArea = {
              left: (x / pageWidth) * 100,
              top: (y / pageHeight) * 100,
              width: (width / pageWidth) * 100,
              height: (height / pageHeight) * 100,
              pageIndex: page,
            };
            return highlightArea;
          }
          return null;
        })
        .filter((highlightArea) => highlightArea !== null);
    }
    return [];
  };

  const modalInfo =
    contractModalPdfDetails &&
    contractModalPdfDetails.length > 0 &&
    contractModalPdfDetails[0].modalHighlights &&
    contractModalPdfDetails[0].modalHighlights.length > 0
      ? contractModalPdfDetails[0].modalHighlights
      : [];

  const firstAreas = convertToHighlightAreas(modalInfo);

  const renderHighlights = (props) => {
    return (
      <div>
        {firstAreas
          ?.filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                ...props.getCssProperties(area, props.rotation),
                background: "yellow",
                opacity: 0.4,
              }}
            />
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const scrollPluginInstance = scrollModePlugin();

  useEffect(() => {
    async function fetchData() {
      const contractFilePath =
        contractModalPdfDetails?.length > 0
          ? contractModalPdfDetails[0]?.filePath
          : null;
      if (auth && contractFilePath) {
        const token = await auth.currentUser.getIdToken();
        handleContractUrl(token, contractFilePath);
      }
    }
    fetchData();

    // Set the initial page to the first highlight's page
    if (firstAreas.length > 0) {
      setInitialPage(firstAreas[0].pageIndex);
    }
  }, [auth, contractModalPdfDetails]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setContractModalPdfDetails();
        setOpen(!open);
      }}
      className="relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg lg:min-w-[50vw] lg:overflow-hidden h-[80vh] bg-white text-center shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="spinner"></div>
              </div>
            ) : pdfUrl ? (
              <Viewer
                fileUrl={pdfUrl}
                plugins={[highlightPluginInstance, scrollPluginInstance]}
                initialPage={initialPage}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <p>No PDF available</p>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
