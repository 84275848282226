"use client";

import { useState, useContext } from "react";
import { CoPilotContext } from "../../context/co-pilot-context";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { documentTypes } from "../../lib/constants";
import { useDenialCode } from "../../hooks/useDenialCode";
import { useProcedure } from "../../hooks/useProcedure";
import ActivityTimeline from "../ActivityUpdate";

const appealAdvice = `Aetna determines medical necessity based on services that a physician, exercising prudent clinical judgment, would provide for the purpose of preventing, evaluating, diagnosing, or treating an illness, injury, disease, or its symptoms. To qualify as medically necessary, the services must be:

1. In accordance with generally accepted standards of medical practice: This implies that the service should be backed by credible scientific evidence published in peer-reviewed medical literature and recognized by the relevant medical community. Alternatively, it can be consistent with physician specialty society recommendations and the views of physicians practicing in the relevant clinical areas

2. Clinically appropriate: The type, frequency, extent, site, and duration of the service should be effective for the patient's condition and necessary for diagnosis or treatment. The care provided must align with clinical guidelines and best practices

3. Not primarily for the convenience of the patient, physician, or other health care provider: The care must be essential and not more costly than an alternative that would likely produce the same therapeutic results

To appeal, Recon AI suggests retrieving the medical record to send as an attachment to the Insurer with an Appeal Letter that references the medical necessity as indicated by the doctor. You can use Recon Copilot to automate the generation of the Appeal Letter.
`;

export const PolicyModal = (props) => {
  const { open, setOpen, policyItem } = props;
  const { denialCodeData } = useDenialCode(policyItem?.denialCode);
  const { procedure } = useProcedure(policyItem.procedure);

  const { updateLocalStorage } = useContext(CoPilotContext);

  const InfoItem = ({ label, value }) => (
    <div className="flex justify-between py-2 border-b border-gray-200 last:border-b-0">
      <dt className="text-sm text-gray-500">{label}</dt>
      <dd className="text-sm font-medium text-gray-900">{value}</dd>
    </div>
  );

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <DialogTitle
                as="h3"
                className="text-xl font-semibold leading-6 text-gray-900 mb-4"
              >
                Claim Details
              </DialogTitle>

              <div className="space-y-6">
                <div className="bg-gray-50 rounded-md p-4">
                  <InfoItem label="Claim ID" value={policyItem.claim} />
                  <InfoItem
                    label="CPT/ICD Procedure Code"
                    value={policyItem.procedure}
                  />
                  <InfoItem label="Denial Code" value={policyItem.denialCode} />
                  <InfoItem
                    label="Remark Code"
                    value={policyItem.remarkCode || "-"}
                  />
                </div>

                <div>
                  <h4 className="text-base font-semibold text-gray-900 mb-2">
                    Denial Code / Remark Code Details
                  </h4>
                  <div className="bg-gray-100 rounded-md p-4 text-sm text-gray-500">
                    {`Denial code ${policyItem.denialCode} is related to ${denialCodeData?.bucket}. ${denialCodeData?.description} `}
                  </div>
                </div>

                <div>
                  <h4 className="text-base font-semibold text-gray-900 mb-2">
                    Procedure Details (CPT/ICD)
                  </h4>
                  <div className="bg-gray-100 rounded-md p-4 text-sm text-gray-500">
                    {`Procedure code ${policyItem.procedure} is for 'Subsequent hospital care' `}
                  </div>
                </div>

                <ActivityTimeline />

                <div>
                  <h4 className="text-base font-semibold text-gray-900 mb-2">
                    Related Policies for this Claim
                  </h4>
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {[1, 2, 3].map((i) => (
                      <li
                        key={i}
                        className="flex items-center py-3 pl-3 pr-4 text-sm"
                      >
                        <PaperClipIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400 mr-3"
                          aria-hidden="true"
                        />
                        <span className="font-medium text-blue-600 hover:underline cursor-pointer">
                          {`Aetna Policy Document 11-20-2023.pdf`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h4 className="text-base font-semibold mb-2 flex items-center">
                    <span className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                      Recon Denials AI
                    </span>
                    <span className="text-gray-900 ml-2">
                      Claim Appeal Suggestion
                    </span>
                  </h4>
                  <div
                    className="bg-gray-100 rounded-md p-4 text-sm text-gray-500"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {appealAdvice}
                  </div>
                </div>

                <div style={{ height: 100 }}>
                  <h4 className="text-base font-semibold text-gray-900 mb-2">
                    <span className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                      Recon Copilot
                    </span>{" "}
                    <span className="text-gray-900 ml-2">Automate</span>
                  </h4>
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Select Task
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </MenuButton>

                    <MenuItems
                      transition
                      className="absolute right-0  z-10 mt-2 w-[100%] overflow-x-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <div className="py-1 px-2 ">
                        <MenuItem>
                          <a
                            onClick={() => {
                              window.open(
                                `${
                                  window.location.origin
                                }/ai-phone-agent?openNewTaskModal=true&showNewTask=true&phoneNumber=123-456-7890&template=${`Claim Appeal Status Check`}&notes=${`Please inquire about the status of our appeal for denied claim ${policyItem.claim}, with denial code ${policyItem.denialCode}`}`,
                                "_blank"
                              );
                            }}
                            className="block px-4 cursor-pointer py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                          >
                            Generate a Call Task
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            onClick={() => {
                              updateLocalStorage("useParams", true);
                              updateLocalStorage(
                                "userQuery",
                                `Explain the ${policyItem.payer} Policy for ${denialCodeData?.bucket} (Denial Code ${policyItem.denialCode}) on CPT Procedure '${procedure?.label}' (${policyItem.procedure}). Then generate an letter for appealing this denied claim.`
                              );
                              window.open(
                                `${window.location.origin}/chat`,
                                "_blank"
                              );
                            }}
                            className="block px-4 py-2 text-sm cursor-pointer text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                          >
                            Generate Appeal Letter
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            onClick={() => {
                              updateLocalStorage("useParams", true);
                              updateLocalStorage(
                                "userQuery",
                                `Explain the ${policyItem.payer} Policy for  ${denialCodeData?.bucket} (Denial Code ${policyItem.denialCode}) on CPT Procedure '${procedure?.label}' (${policyItem.procedure})`
                              );
                              window.open(
                                `${window.location.origin}/chat?document_type=${documentTypes[1]}`,
                                "_blank"
                              );
                            }}
                            className="block px-4 py-2 cursor-pointer text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                          >
                            {`Explain the ${policyItem.payer} Policy for this Denial`}
                          </a>
                        </MenuItem>
                        {/* <MenuItem>
                            <a
                              onClick={() => {
                                updateLocalStorage("useParams", true);
                                updateLocalStorage(
                                  "userQuery",
                                  `Find Relevant Sections in ${policyItem.payer}’s Policies for Denial Code`
                                );
                                window.open(
                                  `${window.location.origin}/chat`,
                                  "_blank"
                                );
                              }}
                              className="block px-4 py-2 cursor-pointer text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              {`Find Relevant Sections in ${policyItem.payer}’s Policies for Denial Code`}
                            </a>
                          </MenuItem> */}
                      </div>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
