// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3GarU6nagTR97-YBClNhq1k1eehpb79c",
  authDomain: "hc-financial-os-mvp.firebaseapp.com",
  projectId: "hc-financial-os-mvp",
  storageBucket: "hc-financial-os-mvp.appspot.com",
  messagingSenderId: "779340479776",
  appId: "1:779340479776:web:1e94e6748e93e411b2eb45",
  measurementId: "G-Y81K42N979",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

export const auth = getAuth(app);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
// Initialize Firebase Auth provider
const analytics = getAnalytics(app);

export const db = getFirestore(app);

export default app;
