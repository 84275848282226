import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "6/3/2024",
    "Estimated Reimbursement": 1579.0,
    Amount: 1969,
  },
  {
    name: "6/10/2024",
    "Estimated Reimbursement": 3058.0,
    Amount: 3519,
  },
  {
    name: "6/17/2024",
    "Estimated Reimbursement": 3159.28,
    Amount: 3710,
  },
  {
    name: "6/24/2024",
    "Estimated Reimbursement": 2415.38,
    Amount: 3060,
  },
];

export default function App() {
  return (
    <ComposedChart
      width={850}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }}
    >
      <CartesianGrid stroke="#f5f5f5" vertical={false}/>
      <YAxis />
      <XAxis dataKey="name" scale="auto"  padding={"no-gap"} tickLine={false} tickMargin={10}/>
      <Tooltip cursor={false} />
      <Legend  style={{ marginTop:10 }}/>
      <Bar dataKey="Estimated Reimbursement" barSize={90} fill="#0d9489" radius={4} />
      <Line type="linear" dot={false} strokeWidth={2} dataKey="Amount" stroke="#4d45e6" />
    </ComposedChart>
  );
}
