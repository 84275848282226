import React from "react";

export const SidebarSkeletonLoader = () => {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Recon OS
          </h1>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {[...Array(5)].map((_, index) => (
                  <li key={index} className="flex items-center gap-x-3 p-2">
                    <div className="h-6 w-6 bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="h-4 w-40 bg-gray-200 rounded animate-pulse mb-2"></div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                <li className="flex items-center gap-x-3 p-2">
                  <div className="h-6 w-6 bg-gray-200 rounded-lg animate-pulse"></div>
                  <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                </li>
              </ul>
            </li>
            <li className="mt-auto">
              <div className="flex items-center gap-x-3 p-2">
                <div className="h-6 w-6 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
