"use client";

import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { ShimmerButton } from "../ShimmerButton"

const NewTaskView = (props) => {
  const { setOpen, setShowNewTask, handleAddItemToDb } = props;
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [template, setTemplate] = useState("");
  const [notes, setNotes] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const pastTemplates = [
    "Prior Authorization",
    "Insurance Verification",
    "Claim Appeal Status Check",
  ];

  const pastPhoneNumbers = [
    {
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      phoneNumber: "123-456-7890",
    },
    {
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      phoneNumber: "123-456-7890",
    },
    {
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      phoneNumber: "234-567-8901",
    },
    {
      imageUrl:
        "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      phoneNumber: "345-678-9012",
    },
  ];

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
    setShowDropdown(e.target.value !== "");
  };

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
    setShowTemplateDropdown(e.target.value !== "");
  };

  const filteredPhoneNumbers = phoneNumber
    ? pastPhoneNumbers.filter((number) =>
      number.phoneNumber.includes(phoneNumber)
    )
    : [];

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    // Set state from query parameters if they exist
    if (queryParams.has("phoneNumber")) {
      setPhoneNumber(queryParams.get("phoneNumber"));
    }
    if (queryParams.has("template")) {
      setTemplate(queryParams.get("template"));
    }
    if (queryParams.has("notes")) {
      setNotes(queryParams.get("notes"));
    }
  }, [location.search]);

  return (
    <div className="flex flex-col w-full sm:space-y-[2rem] lg:space-y-[2rem] py-[0.5rem]">
      <DialogTitle as="h3" className="text-2xl font-semibold text-center leading-6">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          Recon AI
        </span>
        <span className="text-gray-900">
          {' '} Call Agent
        </span>
        <div className="text-gray-500 font-normal mt-4 text-xl" style={{fontSize: 16}}>
          {' '} Generate New Call Task
        </div>
      </DialogTitle>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Phone #
        </label>
        <div className="mt-2">
          <input
            id="phoneNumber"
            type="text"
            placeholder="345-678-9012"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={phoneNumber}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setShowDropdown(false)}
          />

          {showDropdown && (
            <div className="absolute bg-white border border-gray-300 rounded-md mt-1">
              {filteredPhoneNumbers.map((number, index) => (
                <div
                  key={index}
                  className="px-4  space-x-3 flex flex-row items-center py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={() => setPhoneNumber(number.phoneNumber)}
                >
                  <img
                    src={number.imageUrl}
                    className=" h-5 w-5 rounded-full"
                  />
                  <p className="text-sm ">{number.phoneNumber}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Task / Template
        </label>
        <div className="mt-2">
          <input
            id="template"
            type="text"
            placeholder="Prior"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={template}
            onChange={handleTemplateChange}
            onFocus={() => setShowTemplateDropdown(true)}
            onBlur={() => setShowTemplateDropdown(false)}
          />

          {showTemplateDropdown && (
            <div className="absolute bg-white border border-gray-300 rounded-md mt-1">
              {pastTemplates.map((template, index) => (
                <div
                  key={index}
                  className="px-4  space-x-3 flex flex-row items-center py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={() => setTemplate(template)}
                >
                  <p className="text-sm ">{template}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Add any additional details for the call
        </label>
        <div className="mt-2">
          <textarea
            id="notes"
            name="notes"
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={""}
          />
        </div>
        {/* <button
          type="button"
          onClick={async () => {
            setLoading(true);
            if (phoneNumber && template) {
              await handleAddItemToDb(phoneNumber, template, notes);
              setLoading(false);
              setOpen(false);
              setShowNewTask(false);
            }
            setLoading(false);
          }}
          className="rounded-md mt-[2rem] bg-indigo-600 w-full  space-x-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Delegate Task to <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">Recon Phone Agent</span>
        </button> */}
        <ShimmerButton
          onClick={async () => {
            setLoading(true);
            if (phoneNumber && template) {
              await handleAddItemToDb(phoneNumber, template, notes);
              setLoading(false);
              setOpen(false);
              setShowNewTask(false);
            }
            setLoading(false);
          }}
          loading={false}>
          Delegate Task to <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-500 to-pink-500">
            Recon Phone Agent
          </span>
        </ShimmerButton>
      </div>
    </div>
  );
};

export const NewTaskModal = (props) => {
  const location = useLocation();
  const { open, setOpen, handleAddItemToDb } = props;
  const [showNewTaskView, setShowNewTask] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    // Check if the 'showNewTask' query parameter is present and set to 'true'
    if (
      queryParams.has("showNewTask") &&
      queryParams.get("showNewTask") === "true"
    ) {
      setShowNewTask(true);
    } else {
      setShowNewTask(false);
    }
  }, [location.search]); // Depend on location.search to re-run the effect only when the query string changes

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={classNames(
              showNewTaskView
                ? " lg:p-[2rem] lg:max-w-lg "
                : " lg:p-[2rem] lg:max-w-lg ",
              "relative transform overflow-hidden rounded-lg bg-white    px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6   data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            )}
          >
            {showNewTaskView ? (
              <NewTaskView
                handleAddItemToDb={handleAddItemToDb}
                setOpen={setOpen}
                setShowNewTask={setShowNewTask}
              />
            ) : (
              <div className=" sm:space-y-[2rem] lg:space-y-[3rem]">
                <div className="">
                  <button
                    type="button"
                    onClick={() => setShowNewTask(true)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600  px-3 py-2 text-sm font-semibold text-white shadow-sm border hover:bg-indigo-500 hover:text-white hover:border-white border-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Start New Task
                  </button>
                </div>

                <div className="">
                  <button
                    type="button"
                    // onClick={() => setOpen(false)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600   px-3 py-2 text-sm font-semibold text-white shadow-sm border hover:bg-indigo-500 hover:text-white hover:border-white border-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Duplicate and Edit Previous Task
                  </button>
                </div>

                <div className="">
                  <button
                    type="button"
                    // onClick={() => setOpen(false)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600   px-3 py-2 text-sm font-semibold text-white shadow-sm border hover:bg-indigo-500 hover:text-white hover:border-white border-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Upload CSV of Tasks
                  </button>
                </div>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
