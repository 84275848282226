import { useUser } from "../hooks/useUser";
import React, { createContext, useState, useEffect, useContext } from "react";
export const UserContext = createContext();
// Create a provider component
export const UserProvider = ({ children }) => {
  const user = useUser();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

// Custom hook to use the context
export const useUserContext = () => {
  return useContext(UserContext);
};
