import { useState, useEffect } from "react";
import { neon } from "@neondatabase/serverless";

const DATABASE_URL =
  "postgres://neondb_owner:9pT6IAZsboUa@ep-odd-frost-a5v7m5i7.us-east-2.aws.neon.tech:5432/neondb?sslmode=require";

export const useDenialCode = (denialCode) => {
  const [denialCodeData, setDenialCodeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDenialCode = async () => {
      if (!denialCode) return;

      setLoading(true);
      setError(null);

      try {
        const sql = neon(DATABASE_URL);
        const res = await sql`
          SELECT denial_code, description, bucket
          FROM denial_codes
          WHERE denial_code = ${denialCode}
        `;

        // Assuming that denial_code is unique and only one record will be returned
        const fetchedData = res[0] || null;
        setDenialCodeData(fetchedData);
      } catch (error) {
        console.error("Error executing query", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDenialCode();
  }, [denialCode]);

  return { denialCodeData, loading, error };
};
