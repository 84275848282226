import CustomBlockTable from "../../components/CustomBlockTable";

export default function Billing() {
  const policiesContracts = [
    {
      procedure: "25574",
      payer: "Aetna",
      priorAuthorization: "Yes",
      notificationRequirement: "48 hours",
      responseTime: "14 days",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "180 days",
    },
    {
      procedure: "25574",
      payer: "UHC",
      priorAuthorization: "Yes",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "180 days",
    },
    {
      procedure: "25574",
      payer: "BCBS CA",
      priorAuthorization: "Yes*",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25574",
      payer: "TriCare",
      priorAuthorization: "Yes*",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25574",
      payer: "Humana",
      priorAuthorization: "Yes",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25215",
      payer: "Aetna",
      priorAuthorization: "Yes",
      notificationRequirement: "48 hours",
      responseTime: "14 days",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25215",
      payer: "UHC",
      priorAuthorization: "Yes",
      notificationRequirement: "48 hours",
      responseTime: "14 days",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25215",
      payer: "BCBS CA",
      priorAuthorization: "Yes*",
      notificationRequirement: "48 hours",
      responseTime: "14 days",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25215",
      payer: "TriCare",
      priorAuthorization: "Yes*",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
    {
      procedure: "25215",
      payer: "Humana",
      priorAuthorization: "Yes",
      notificationRequirement: "n/a",
      responseTime: "n/a",
      maxVisitsDuration: "n/a",
      timeBetweenServices: "60 days",
    },
  ];
  const policiesHeaders = [
    "Procedure",
    "Payer",
    "Prior Auth",
    "Notification Req.",
    "Response Time",
    "Max Visits Dur.",
    "Time Between",
    "Submission Site",
  ];

  return (
    <>
      <CustomBlockTable
        title={"Prior Authorization Policies"}
        policiesContracts={policiesContracts}
        policiesHeaders={policiesHeaders}
        col={true}
        colNumber="grid-cols-8"
      />
    </>
  );
}
