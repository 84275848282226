import { useState, useEffect, useContext } from "react";
import { query, where, getDocs, collection } from "firebase/firestore";
import axios from "axios";
import { getAuth } from "firebase/auth";

import Table from "../../components/CustomizeTable";
import { db } from "../../firebase-config";
import { ModalContext } from "../../context/modal-context";
import { useUserContext } from "../../context/user-context";
import { RECON_OS_SERVER_URI } from "@/lib/services";

export default function Billing() {
  const auth = getAuth();
  const {
    setOpenPdfModal,
    setStartPage,
    setOpenPdfViewer,
    setContractModalPdfDetails,
  } = useContext(ModalContext);
  const { userInformation } = useUserContext();

  const [contracts, setContracts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const getDemoContracts = async (organizationId) => {
    try {
      const q = query(
        collection(db, "contract_documents"),
        where("organizationId", "==", organizationId)
      );
      const querySnapshot = await getDocs(q);

      const contractData = querySnapshot.docs.map((doc) => doc.data());
      console.log("The Contracts ---->", contractData);

      setContracts(contractData);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("fetched contracts");
    }
  };

  const getContracts = async (token, organizationId, pageSize = 10) => {
    try {
      const response = await axios.get(`${RECON_OS_SERVER_URI}/contracts`, {
        headers: {
          Authorization: token,
        },
        params: {
          organization_id: organizationId,
          page: currentPage,
          pageSize: pageSize,
        },
      });

      setContracts(response.data);

      console.log("Data fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (userInformation?.organizationId) {
        const token = await auth.currentUser.getIdToken();
        if (userInformation?.organizationId === 1) {
          getDemoContracts(userInformation?.organizationId);
        } else {
          getContracts(token, userInformation?.organizationId);
        }
      }
    }
    fetchData();
  }, [userInformation]);

  const totalPages = Math.ceil(contracts.length / itemsPerPage);

  const demoContractHeaders = [
    "Payer",
    "Contract Entity",
    "Effective date",
    "Initial Term",
    "Renewal Date",
    "Notification Period",
    "Contract Details",
  ];

  const contractHeaders = [
    "Payer",
    "File Name",
    "Document Title",
    // "Plans",
    // "Umbrella Contract",
    // "Terms",
  ];

  const truncateText = (text, maxLength = 30) => {
    if (text?.length > maxLength) {
      return text.substr(0, maxLength - 3) + "...";
    }
    return text;
  };

  const demoContractColumns = [
    {
      render: (item) => item.payer,
      className:
        "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium  text-gray-900  sm:pl-6",
    },
    {
      render: (item) => item.contractEntity,
      className:
        "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },
    {
      render: (item) => (
        <p onClick={() => setOpenPdfModal(true)}>{item.effectiveDate}</p>
      ),
      className:
        "whitespace-nowrap text-center underline cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },
    {
      render: (item) => (
        <p onClick={() => setOpenPdfModal(true)}>{item.initialTerm}</p>
      ),
      className:
        "whitespace-nowrap text-center underline cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },
    {
      render: (item) => item.renewalDate,
      className:
        "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium  text-gray-900 sm:pl-6",
    },
    {
      render: (item) => (
        <p onClick={() => setOpenPdfModal(true)}>{item.notificationPeriod}</p>
      ),
      className:
        "whitespace-nowrap text-center underline  cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },

    {
      render: (item) => (
        <button
          onClick={() => setOpenPdfModal(true)}
          className="bg-teal-600 hover:bg-teal-700 text-white text-xs font-medium py-1 px-3 rounded transition duration-300"
        >
          {item.contractDetails}
        </button>
      ),
      className:
        "whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium sm:pl-6",
    },
  ];

  const defaultContractColumns = [
    {
      render: (item) => {
        console.log("item in render", item.highlights);
        return (
          <p
          // onClick={() => {
          //   setStartPage(2);
          //   setContractModalPdfDetails([
          //     {
          //       filePath: item.file_path,
          //       modalHighlights: item.highlights,
          //     },
          //   ]);
          //   setOpenPdfViewer(true);
          // }}
          >
            {item.payer}
          </p>
        );
      },
      className:
        "whitespace-nowrap  text-center  py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },
    {
      render: (item) => (
        <p
          onClick={() => {
            setStartPage(2);
            setContractModalPdfDetails([
              {
                filePath: item.file_path,
                modalHighlights: item.highlights,
              },
            ]);
            setOpenPdfViewer(true);
          }}
        >
          {truncateText(item.file_name)}
        </p>
      ),
      className:
        "whitespace-nowrap py-4 text-center pl-4 pr-3 text-sm font-medium text-blue-600 underline cursor-pointer sm:pl-6 truncate",
    },
    {
      render: (item) => (
        <p
        // onClick={() => {
        //   setStartPage(2);
        //   setContractModalPdfDetails([
        //     {
        //       filePath: item.file_path,
        //       modalHighlights: item.highlights,
        //     },
        //   ]);
        //   setOpenPdfViewer(true);
        // }}
        >
          {item.document_title}
        </p>
      ),
      className:
        "whitespace-nowrap  truncate py-4 text-center pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
    },
  ];

  console.log("Contracts", contracts);
  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 overflow-hidden overflow-y-hidden bg-gray-100">
      <div className="min-h-screen  bg-gray-100 overflow-hidden">
        <div className=" py-4">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-gray-900">
                Contracts
              </h1>
            </div>
          </div>
        </div>
        {userInformation?.organizationId === 1 ? (
          <Table
            headers={demoContractHeaders}
            data={contracts}
            columns={demoContractColumns}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalPages={totalPages}
            isNextDisabled={true}
          />
        ) : (
          <Table
            headers={contractHeaders}
            data={contracts}
            columns={defaultContractColumns}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalPages={totalPages}
            isNextDisabled={true}
          />
        )}
      </div>
    </div>
  );
}
